<template>
    <div class="container">

        <div class="answer-details-container">
            <div style="flex-grow: 8;">
                <span class="details-span">Answer: {{ answer.answer.text }}</span>
                <span class="details-span">Details: {{ answer.answer?.details }}</span>
                <span class="details-span">Start And End Dates: {{ getStartAndEndDates }}</span>
            </div>
            <div style="flex-grow: 1;">
                <span class="details-span">Reward Amount: {{ answer.rewardAmount }}</span>
                <span class="details-span">Reward Currency: {{ currencyText }}</span>
            </div>
            <div class="actions-buttons">
                <Button label="Edit" @click="openCreateEditAnswerModal(answer)" class="green-button__secondary"/>
                <Button label="Delete" @click="openDeleteAnswerModal(answer)" class="red-button__secondary"/>
            </div>
        </div>

        <Accordion @tabOpen="onExpendClick">
            <AccordionTab>
                <template #header>
                    <div class="hints-header">
                        <span>Clues</span>
                        <Button label="Add Clue" @click.stop="openCreateHintModal()" class="green-button__secondary"/>
                    </div>
                </template>
                <DataTable v-if="hints?.length > 0" scrollable scrollHeight="flex" @rowReorder="onHintReorder" :value="hints"
                    class="p-datatable-sm">
                    <Column rowReorder style="max-width: 2rem;" />
                    <Column style="width: 100%;">
                        <template #body="hint">
                            <span style="flex-grow: 1; word-break: break-word;">{{ hint.data.hint.text }}</span>
                            <div class="actions-buttons">
                                <Button label="Delete" @click="openDeleteHintModal(hint.data)" class="red-button__secondary"/>
                            </div>
                        </template>
                    </Column>
                </DataTable>
            </AccordionTab>
        </Accordion>
    
    </div>
    <CreateEditPuzzleAnswerHintModal ref="createEditPuzzleAnswerHintModal" :selectedContest="selectedContest"
        :selectedPuzzle="selectedContestPuzzle" />
</template>

<script>
import { mapState } from 'vuex';
import PuzzleService from '../../../../service/PuzzleService';

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import CreateEditPuzzleAnswerHintModal from './CreateEditPuzzleAnswerHintModal.vue';
import TimeUtils from '../../../../utilities/TimeUtils';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import { getCurrencyText } from '../..';
import EventBus from '../../../../event-bus';

export default {
    name: "PuzzleEditAnswerItem",
    emits: ["open-create-edit-answer-modal", "open-delete-modal"],
    props: {
        selectedContest: {
            type: Object,
            required: false
        },
        answer: {
            type: Object,
            required: false
        },
    },
    data() {
        return {
            showHints: false,
            hints: null,
        };
    },
    components: {
    DataTable,
    Column,
    CreateEditPuzzleAnswerHintModal,
    Accordion,
    AccordionTab
},
    computed: {
        ...mapState(['selectedContestPuzzle']),

        getStartAndEndDates() {
            return TimeUtils.getFormattedTime(this.answer.activeStartDate, 'YYYY/MM/DD') 
                + ' - ' + TimeUtils.getFormattedTime(this.answer.activeEndDate, 'YYYY/MM/DD');
        },

        currencyText() {
            return getCurrencyText(this.answer.currencyId);
        },
    },
    mounted() {
    },
    methods: {
        initHintsEvents(){
            EventBus.off('refresh-puzzle-answer-hints');
            EventBus.on('refresh-puzzle-answer-hints', (answerId)=>{
                if(this.showHints && this.answer.answerId == answerId){
                    this.fetchPuzzleAnswerHints();
                }
            });
        },

        fetchPuzzleAnswerHints() {
            PuzzleService.getPuzzleAnswerHints(this.selectedContest.contestId, this.selectedContestPuzzle.puzzleId, this.answer.answerId, 100, 0).then(resp => {
                if (resp.data.status == 'success') {
                    this.hints = resp.data.hints;
                    console.log("getPuzzleAnswerHints", resp.data);
                }
            });
        },

        onExpendClick() {
            this.showHints = !this.showHints;
            this.fetchPuzzleAnswerHints();
        },

        onHintReorder(event) {
            this.hints = event.value;
            const hintsIds = this.hints.map(hint => hint.hintId);
            console.log("updatePuzzleAnswerHintOrdering", event.value, hintsIds);
            PuzzleService.updatePuzzleAnswerHintOrdering(
                this.selectedContest.contestId, this.selectedContestPuzzle.puzzleId, this.answer.answerId, hintsIds)
                .then(resp => {
                if (resp.data.status == "success") {
                    console.log("success", resp);
                }
            });
        },

        openCreateHintModal() {
            this.initHintsEvents();
            this.$refs.createEditPuzzleAnswerHintModal.open(this.answer);
        },

        openCreateEditAnswerModal(answer) {
            this.$emit("open-create-edit-answer-modal",answer);
        },

        openDeleteAnswerModal(answer) {
            this.$emit("open-delete-modal",{answer: answer});
        },

        openDeleteHintModal(hint) {
            this.initHintsEvents();
            this.$emit("open-delete-modal",{hint: hint});
        },
    }
};
</script>

<style scoped>
.container {
    width: 100%;
}

::v-deep(.p-accordion .p-accordion-header .p-accordion-header-link) {
    padding: 4px 1rem;
}

.details-span {
    display: block;
}

.hints-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.answer-details-container {
    display: flex;
    flex-direction: row;
    gap: 16px;
    margin-bottom: 8px;
}


.answer-details-container div {
    display: flex;
    flex-direction: column;
    gap: 8px;
}


.actions-buttons {
    justify-content: flex-end;
    display: flex;
    flex-direction: row !important;
    align-items: center;
}

.actions-buttons button {
    height: fit-content;
}

.expend-icon-container {
    padding: 1px;
}



::v-deep(.p-datatable-thead) {
    display: none !important;
}
</style>