<template>
    <LoadingSpinner :showLoading="competitionsDetailsLoading" v-if="competitionsDetailsLoading" />
    
    <div class="stats-container" >
        <div :class='"flex-column "+(miniVersion ? "" : "card")'>
            <div class="flex-row competition-header-row">
                <div class="flex-column competition-header-section">
                    <div class="flex-row" style="margin-bottom: 8px;">
                        <UserAvatar :imageSrc="competition?.iconUrl" size='lg' shape='roundedSquare' />
                        <div class="flex-column" style="gap: 8px; padding: 0px 16px 8px;">
                            <span style="font-size: 20px;">
                                {{ competition?.name }}
                            </span>
                            <div>
                                <span>Hosted by: </span>
                                <span class="hosted-by-label" @click.stop='goToGroupProfile(competition?.hostingGroup?.groupId)'>
                                    {{ competition?.hostingGroup?.name }}
                                </span>
                            </div>
                            <span v-if="competition?.privateContest" style='font-style:italic;'>This is a private competition.</span>
                            <span v-if="competition?.registered">You are a registered contestant</span>
                            <span v-if="canAward">🏁This competition has ended.</span>
                            
                            <Button v-else-if=" isDuringRegistrationTime && !competition?.registered" 
                                class="green-button__secondary" label="Register" @click="confirmCompetitionDetails"/>
                            <div v-if="competition?.registered" style="display:flex;flex-direction:row;">
                                <Button v-if="!hasStarted" class="green-button__secondary" label="Withdraw" @click="confirmWithdrawal"  v-tooltip.bottom="'Withdraw from competition.'" style="margin-right:3px;"/>
                                <Button  v-if="(competition.allowLevel2 && !isPremiumSubscriber)" class="purple-button__secondary " label="Level Up!" v-tooltip.bottom="'Looking for a competitive edge?  Get Access to Level 2 Competition Trading data.'" @click="showLevel2Modal"/>
                            </div>
                        </div>
                        <Button class="green-button__secondary registration-link-button" label="Copy Registration Link" @click="getRegistrationParam" v-if="isGroupOwnerAdminOrMod && !isMobile"/>
                        
                    </div>
                    <span>Description: {{ competition.shortDescription }}</span>
                    <Button class="green-button__secondary registration-link-button" label="Copy Registration Link" @click="getRegistrationParam" v-if="isGroupOwnerAdminOrMod && isMobile"/>
                </div>
                <img class="competition-icon" src="assets/images/competition.png" v-if="!isMobile"/>
            </div>
            
            
            <Accordion>
                <AccordionTab header="Details">
                    <div class="data-container">
                        <div>
                            <span>Registration Start: </span>
                            <span>{{ getCompetitionDateFormatted(competition?.registrationStartDate) }}</span>
                        </div>
                        <div>
                            <span>Registration End: </span>
                            <span>{{ getCompetitionDateFormatted(competition?.registrationEndDate) }}</span>
                        </div>
                        <div>
                            <span>Competition Start: </span>
                            <span>{{ getCompetitionDateFormatted(competition?.startDate) }}</span>
                        </div>
                        <div>
                            <span>Competition End: </span>
                            <span>{{ getCompetitionDateFormatted(competition?.endDate) }}</span>
                        </div>
                        <div>
                            <span>Type: </span>
                            <span>{{ getCompetitionType(competition?.competitionTypeId) }}</span>
                        </div>
                        <div>
                            <span>Registration: </span>
                            <span>{{ getCompetitionRegistrationType(competition) }}</span>
                        </div>
                        <div>
                            <span>Skill Level: </span>
                            <span>{{ getCompetitionSkillLevel(competition?.skillLevelId) }}</span>
                        </div>
                        <div>
                            <span>Competition Type: </span>
                            <span>{{ getCompetitionCategory(competition?.categoryId) }}<span v-if="competition?.openPortfolio">
                                    (Open)</span></span>
                        </div>

                        <div>
                            <span>Currency: </span>
                            <span>
                            {{(competition.currencyId == 1? 'CAD': 'USD')}}
                                </span> 
                        </div>
                        <div>
                            <span>Starting Cash Balance: </span>
                            <span>
                            {{formatter.format(competition.minStartingCash, '$0,0.00')}}
                                </span> 
                        </div>
                        <div>
                            <span>Min Short Price: </span>
                            <span>
                            {{((competition.minShortPrice && competition.minShortPrice > 0)? formatter.format(competition.minShortPrice, '$0,0.00') : 'none')}}
                                </span> 
                        </div>
                        <div>
                            <span>Min Long Price: </span>
                            <span>
                            {{((competition.minLongPrice && competition.minLongPrice > 0)? formatter.format(competition.minLongPrice, '$0,0.00') : 'none')}}
                                </span> 
                        </div>
                        <div>
                            <span>Trading Delay: </span>
                            <span>
                            {{(competition.maxTradingDelay > 0 ? "On" : 'Off')}}
                                </span> 
                        </div>
                         <div>
                            <span>Level 2 Competition Trading Data: </span>
                            <span>
                            {{(competition.allowLevel2 > 0 ? "Available" : 'Disabled')}}
                                </span> 
                        </div>
                        <div v-if="competition.maxContestants">
                                <span>Max Contestants: </span>
                                <span>
                                {{competition.maxContestants}}
                                   </span> 
                               
                            </div>
                        <div v-if="competition.issuePass">
                            <span>This competition includes a Basic Investor Pass to avoid usage limits.</span>
                            
                        </div>
                    </div>
                    <div v-if="longDescription" class="long-description-container">
                        <span v-html="longDescription"></span>
                    </div>
                </AccordionTab>
            </Accordion>
            <template v-if="showPrivateMessage">
                <span class="your-stats-table-header">Stats only visible to contestants.</span>
            </template>
            <template v-if="userStats && userStats.length > 0 && userStats[0].analyst">
                <span class="your-stats-table-header">Your Stats:</span>

                <DataTable scrollable :value="userStats" class="p-datatable-sm">
                    <Column field="ranking" header="#" class="ranking-column" />
                    <Column header="Name" class="username-column">
                        <template #body="slotProps">
                            <span class="username-label" @click='onContestantClicked(slotProps.data)'
                                v-tooltip.bottom="'@' + slotProps.data.analyst.name">
                                @{{ slotProps.data.analyst.name }}
                            </span>
                        </template>
                    </Column>
                    <Column class="return-column" header="%">
                        <template #body="slotProps">
                            {{ Math.ceil(slotProps.data?.returnValue * 100)/100 }}
                        </template>
                    </Column>
                    <Column class="other-column" :header="(isMobile ? '$S' : '$Start')">
                        <template #body="slotProps">
                            {{ formatter.format(slotProps.data.startingValue, '$0.00') }}
                        </template>
                    </Column>
                    <Column class="other-column" :header="(isMobile ? '$C' : '$Current')">
                        <template #body="slotProps">
                            {{ formatter.format(slotProps.data.currentValue, '$0.00') }}
                        </template>
                    </Column>
                    <Column class="award-column" :header="(isMobile ? '&nbsp;&nbsp;A' : 'Awards')" v-if="canAward" >
                    <template #body="slotProps">
                        
                            <span v-if="slotProps.data.award" class="award-span" 
                                v-tooltip.bottom="getAwardName(slotProps.data.award)">
                                <img :src="getAwardIcon(slotProps.data.award)" />
                            
                            </span>
                        
                    
                    </template>
                </Column>
                </DataTable>
            </template>
        </div>
        <div :class='"flex-column "+(miniVersion ? "" : "card")'>
            <div style="display:flex;flex-direction:row;align-items:flex-end;margin-bottom:5px;margin-top:5px;" class="p-fluid" >
            <span class="all-stats-table-header">Current Stats:</span>
            <AutoComplete ref='groupCompSearchBar' id='groupCompSearchBar' placeholder="Filter by Group" forceSelection v-model="selectedGroup" style="margin-left:auto;min-width:40%;"
							:suggestions="filteredGroups" @complete="searchGroups($event)" field="name"
							@item-select="onGroupSelected($event)"
							class="comp-group-search" >
							<template #item="slotProps">
                                    <UserAvatar :imageSrc="slotProps.item.avatar" size="md" shape="roundedSquare"/> 
                            
									<span>{{slotProps.item.name}}</span>
									
								</template>
						</AutoComplete>
            <Button  icon="pi pi-times" class="clear-group-button"  text rounded @click="clearSelectedGroup" v-tooltip.bottom="'Clear Selected Group'"/>
					
            </div>
            <CompetitionStatsTable  v-if="competitionStats && competitionStats.allUserStats && competitionStats.allUserStats.length > 0"
                :competition="competition" :loading="competitionStatsTableLoading" @load-page="loadPage"
                :allUserStats="competitionStats.allUserStats" 
                :competitionStatsLimit="competitionStatsLimit" :totalStats="competitionStats.totalStats"
                @contestant-clicked="onContestantClicked" :canAward="canAward" :isGroupOwnerAdminOrMod="isGroupOwnerAdminOrMod" />
        </div>
        <div v-if="((competition?.registered && isPremiumSubscriber && competition.allowLevel2 ) || isGroupOwnerAdminOrMod) " :class='"trade-orders-container "+(miniVersion ? "" : "card")'>
            <div class="trade-orders-top-container">
                <span style="white-space: nowrap;">Level 2 Trading Data:</span>

                <Calendar dateFormat="yy/mm/dd" showIcon v-model="competitionOrdersDateRange" :minDate="competitionStartDate" 
                    :maxDate="competitionEndDate" selectionMode="range" :manualInput="false"/>

                <TransfereeFundsSearch :selectedGroup="competition?.hostingGroup" @selected-transferee="selectCompetitionOrdersUser" v-if="isGroupOwnerAdminOrMod"/>
            </div>

            <OrdersTable v-if="competitionOrders" :competition="competition" :orders="competitionOrders" :limitPerPage="competitionOrdersLimit" :orderTotal="competitionOrdersTotal" 
                :loadingOrders="competitionOrdersLoading" :pendingOrdersOnly="false" @load-more-orders="fetchCompetitionTradeOrders"
                @user-clicked="onContestantClicked"
                @on-sort="onTradeOrderSort"/>
        </div>
    </div>

    <HoldingsTableModal v-if="!isMobile" ref="holdingsTableModal" :competition="competition" />
    <ConfirmModal headerText="Confirm Registration" ref="confirmDetailsModal" style="max-width:500px;"  @confirmed="onDetailsConfirmed" :confirmButtonGreen="true">
        <template #content>
            <div style="display:block;padding:15px;">
                <div><p>You are registering for <b>{{competition.name}}</b>.</p>
                <p>
                    Please review the competition details below including all rules, disclaimers and eligibility requirements before registering.
                </p>
                <p><b>Competition Details:</b></p>
                </div>
                <div class="long-description-container">
                    
                   <p> {{competition.longDescription}}</p>
                </div>
                    
                
                <div style="margin-left:auto;margin-right:auto;text-align:center;">
                    <p></p>
                    <p><b>Continue with registration?</b></p>
                     <p></p>
                </div>
            </div>
        </template>
    </ConfirmModal>

     <ConfirmModal headerText="Confirm Withdrawal" ref="confirmWithdrawalModal" @confirmed="onWithdrawalConfirmed" :confirmButtonLabel="'Yes'" :cancelButtonLabel="'No'">
        <template #content>
            <div style="display:block;padding:10px;">
                <p><strong>Are you sure you want to withdraw from the competition?</strong></p>
                
            </div>
        </template>
    </ConfirmModal>
     <ConfirmModal headerText="Level Up!" ref="confirmLevelUpModal" @confirmed="onLevelUpConfirmed" :confirmButtonLabel="'See Plans/Passes'" :cancelButtonLabel="'Close'" :confirmButtonGreen="true">
        <template #content>
            <div style="display:block;padding:10px;max-width:400px;">
                <p>Looking for a competitive edge? </p> 
                <p>You can get Access to Level 2 Competition Trading data by purchasing a Premium Investor Pass or Premium Subscription.</p>
                <p>Level 2 Competition Trading Data includes all trades being made during the competition in real time.  This includes both pending and completed trades.
                </p>
                <p>Use this information see what's trading and what's not.  Find opportunities you otherwise wouldn't have thought of and see if others are onto what you already know.</p>
                
                
                
            </div>
        </template>
    </ConfirmModal>
     <RegCodeModal  ref="regCodeModal" @confirmed="onRegConfirmed" @cancelled="onRegCancelled"/>
</template>

<script>
import CompetitionStatsTable from '../components/CompetitionStatsTable.vue';
import LoadingSpinner from '../../../components/common/LoadingSpinner.vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import UserAvatar from '../../profile/UserAvatar.vue';
import OrdersTable from '../../portfolio/overview/OrdersTable.vue';
import Calendar from 'primevue/calendar';
import TransfereeFundsSearch from '../../portfolio/TransfereeFundsSearch.vue';
import HoldingsTableModal from '../../portfolio/modal/HoldingsTableModal.vue';

import {APP_URL} from '../../../common/config';
import FormattingUtils from '../../../utilities/FormattingUtils';
import BrowserUtils from '../../../utilities/BrowserUtils';
import GroupService from '../../../service/GroupService';
import EventBus from '../../../event-bus';
import { format, add, isAfter, isBefore, startOfDay} from "date-fns";
import { getDataFromOrders } from '../../portfolio/';
import { getCompetitionCategory, getCompetitionSkillLevel, getCompetitionRegistrationType, getCompetitionDateFormatted, getCompetitionType } from '../index';
import UserService from '../../../service/UserService';
import {AWARDS} from '../../contests/index';

import ConfirmModal from '../../modal/ConfirmModal.vue';

import RegCodeModal from './RegCodeModal.vue';
import UserUtils from '../../../utilities/UserUtils';


import AutoComplete from 'primevue/autocomplete';

const sortStatIds = {
    cumulativeReturn: 1,
    streak: 2,
    numAnswered: 3,
    numCorrect: 4,
    amountRewarded: 5,
};

export default {
    name: 'CompetitionStats',
    props: {
        miniVersion: {
            type: Boolean,
            required: false,
            default: false
        },
        isGroupOwnerAdminOrMod: {
            type: Boolean,
            required: false,
            default: false
        },
        competition: {
            type: Object,
            required: true
        },
        cycle: {
            type: Object,
            required: true
        },
    },
    components: {
    CompetitionStatsTable,
    LoadingSpinner,
    UserAvatar,
    DataTable,
    Column,
    Accordion,
    AccordionTab,
    OrdersTable,
    Calendar,
    TransfereeFundsSearch,
    HoldingsTableModal,
    ConfirmModal,
    RegCodeModal,
    AutoComplete,
    
},
    data() {
        return {
            getCompetitionCategory, getCompetitionSkillLevel,
            getCompetitionRegistrationType, getCompetitionDateFormatted,
            getCompetitionType,

            formatter: new FormattingUtils(),

            userStats: null,
            competitionsDetailsLoading: true,
            competitionStatsTableLoading: false,
            competitionStats: null,
            competitionStatsLimit: 5,
            competitionStatsOffset: 0,
            selectedSortStatId: sortStatIds.amountRewarded,

            longDescription: null,

            competitionOrders: null,
            competitionOrdersTotal: 0,
            competitionOrdersLoading: false,
            competitionOrdersLimit: 5,
            competitionOrdersOffset: 0,
            competitionOrdersDateRange: null,
            competitionStartDate: null,
            competitionEndDate: null,
            competitionOrdersUser: null,
            showPrivateMessage: false,
            filteredGroups: [],
            selectedGroup: null,
            tradeSortCol: null,
            tradeSortDir: null,

        }
    },

    computed: {
        isMobile() {
            return BrowserUtils.isMobile();
        },

        isDuringRegistrationTime() {
            const today = new Date();
            const registrationStartDate = new Date(this.competition?.registrationStartDate);
            const registrationEndDate = new Date(this.competition?.registrationEndDate);
            // console.log("showRegisterForCompetitionButton",isAfter(today, registrationStartDate), isBefore(today, registrationEndDate));
            return isAfter(today, registrationStartDate) && isBefore(today, registrationEndDate);
        },

        canAward(){
            const now = new Date();
            var b = isAfter(now, startOfDay(add(new Date(this.competition?.endDate), { days: 1 })));
            
            return b ;
        },

        hasStarted() {
             const now = new Date();
            var b = isAfter(now, startOfDay(new Date(this.competition?.startDate)));
            
            return b ;
        },

        isPremiumSubscriber() {
            return UserUtils.isPremiumSubscriber();
        }


    },

    watch: {
        cycle() {
            this.fetchCompetitionStats();
        },

        isGroupOwnerAdminOrMod(newVal){//eslint-disable-line
            //console.log("is group owner admin changed to "+newVal)
            if (!this.competitionOrders && !this.competitionOrdersLoading && this.isGroupOwnerAdminOrMod) {
                this.fetchCompetitionTradeOrders();
            }
        },

        competitionOrdersDateRange(){
            if(this.competitionOrdersDateRange && this.competitionOrdersDateRange[0] && this.competitionOrdersDateRange[1] ){
                this.fetchCompetitionTradeOrders();
            }
        }
    },

    mounted() {
        // console.log("CompetitionStats activated");
        this.tradeSortCol = null;
        this.tradeSortDir = null;
        this.init();
        this.subscribeToEvents();

        if(this.$route.query.register){
            this.confirmCompetitionDetails();
        }

        console.log("isGroupOwnerAdminOrMod = " +this.isGroupOwnerAdminOrMod);
    },

    activated() {
        // console.log("CompetitionStats activated");
        this.tradeSortCol = null;
        this.tradeSortDir = null;
        this.init();
        this.subscribeToEvents();
    },

    methods: {
        onLevelUpConfirmed() {
             this.$router.push('/billing');
        },

        showLevel2Modal() {
            this.$refs.confirmLevelUpModal.open();
            // if( this.isMobile){

            // }
            // else {
            //     this.$router.push('/billing');
            // }
        },
        onGroupSelected(event){
            this.selectedGroup = event.value;
            this.competitionStatsOffset = 0;
            this.fetchCompetitionStats();
           
           
        },

        clearSelectedGroup() {
			
			
			this.selectedGroup = null//{name:"", avatar: null, groupId: 0};
            //this.$refs.groupCompSearchBar.show();
            this.fetchCompetitionStats();
        },
        searchGroups(event) {
            GroupService.searchGroups(0, 1000,  event.query.toLowerCase()).then(resp=>{
                if( resp.data.status == "success"){
                    this.filteredGroups = resp.data.groups;
                }
                else {
                    console.log("error searching groups", resp.data);
                }
            }).catch(err => {
                console.log("error searching groups", err);
            })
        },

        getAwardName(awardId) {
            for( const a of AWARDS ) {
                if( a.awardId == awardId){
                    return a.name;
                }
            }
            return ""; 
        },
        getAwardIcon(awardId) {
           for( const a of AWARDS ) {
                if( a.awardId == awardId){
                    return a.icon;
                }
            }
            return ""; 
        },

        init(){
            this.competitionStartDate = new Date(this.competition.startDate);
            this.competitionEndDate = new Date(this.competition.endDate);
            this.selectedGroup = null;

            this.fetchCompetitionStats();
            this.fetchCompetitionLongDesc();
            if (this.isGroupOwnerAdminOrMod || this.isPremiumSubscriber) {
                this.fetchCompetitionTradeOrders();
            }
        },

        subscribeToEvents() {
            EventBus.off('post-answered');
            EventBus.on('post-answered', activity => {
                // console.log('post-answered', activity);
                if(activity?.contest?.contestId == this.competition?.contestId
                    && activity?.poll?.correctAnswerReward) {
                    this.competitionStatsOffset = 0;
                    this.fetchCompetitionStats();
                }
            });
        },

        goToGroupProfile(groupId) {
            this.$router.push('/groups/profile/' + groupId);
        },

        confirmWithdrawal() {
            this.$refs.confirmWithdrawalModal.open();
        },

        onWithdrawalConfirmed() {
            UserService.withdrawFromContest(this.competition?.contestId).then(resp => {
                if (resp.data.status === 'success') {
                    this.$toast.add({ severity: 'success', summary: 'You have been removed from the competition.', life: 4000, group: 'center' });
                    EventBus.emit('refresh-competitions');
                    this.$router.push(`/contests/competitions/${this.competition?.contestId}`);
                }else if( resp.data.message == 'Contest already started.') {
                    this.$toast.add({ severity: 'error', summary: 'You cannot withdraw from a competition once it has started.', life: 4000, group: 'center' });
                    
                } else {
                    this.$toast.add({ severity: 'error', summary: 'There was an error processing your request.  Please contact support if the problem persists.', life: 4000, group: 'center' });
                }
            }).catch(err => {
                console.err(err);

                this.$toast.add({ severity: 'error', summary: 'There was an error processing your request.  Please contact support if the problem persists.', life: 4000, group: 'center' });
            });
        },

        confirmCompetitionDetails() {
            this.$refs.confirmDetailsModal.open();
        },

        onDetailsConfirmed() {
            this.registerForCompetition();
        },

         onRegConfirmed(code) {
            if( code && code != '' ) {
                UserService.registerForContest(this.$store.state.users.user.userId, this.competition?.contestId, code).then(resp => {
                    if (resp.data.status === 'success') {
                        EventBus.emit('refresh-competitions');
                        EventBus.emit('track-join-competition', this.competition);
                        this.$router.push(`/contests/competitions/${this.competition?.contestId}?justRegistered=true`);
                    } else {
                        this.$toast.add({ severity: 'error', summary: 'Contest Registration Failed!', detail: resp.data.message, life: 8000, group: 'center' });
                    }
                    
                }).catch(err => {
                    console.err(err);

                    this.$toast.add({ severity: 'error', summary: 'Contest Registration Failed!', life: 8000, group: 'center' });
                    
                });
            }
        },

        onRegCancelled() {
            
        },

        registerForCompetition() {
             if( this.competition.registrationCode ) {
                //this.selectedCompetition = competition;
                this.$refs.regCodeModal.open();
            }
            else {
                UserService.registerForContest(this.$store.state.users.user.userId, this.competition?.contestId).then(resp => {
                    if (resp.data.status === 'success') {
                        EventBus.emit('refresh-competitions');
                        EventBus.emit('track-join-competition', this.competition);
                        this.$router.push(`/contests/competitions/${this.competition?.contestId}?justRegistered=true`);
                    } else {
                        this.$toast.add({ severity: 'error', summary: 'Contest Registration Failed!', detail: resp.data.message, life: 8000, group: 'center' });
                    }
                }).catch(err => {
                    console.err(err);

                    this.$toast.add({ severity: 'error', summary: 'Contest Registration Failed!  Contact support if the problem persists.', life: 8000, group: 'center' });
                });
            }
        },

        loadPage(offset) {
            this.competitionStatsOffset = offset;
            this.fetchCompetitionStats();
        },

        selectCompetitionOrdersUser(user) {
            this.competitionOrdersUser = user;
            this.fetchCompetitionTradeOrders();
        },

        onContestantClicked(item) {
            if(!this.isMobile && this.isGroupOwnerAdminOrMod){
                this.$refs.holdingsTableModal.open(item);
            } else {
                this.$router.push(`/analysts/profile/${item.analyst.name}`);
            }
        },
        onTradeOrderSort(event){
            console.log("trade order sort ", event);
            this.tradeSortCol = event.sortField;
            this.tradeSortDir = (event.sortOrder > 0 ? 'asc' : 'desc');
            this.fetchCompetitionTradeOrders(event);
        },

        fetchCompetitionTradeOrders(event) {
            this.competitionOrdersLoading = true;
            this.competitionOrdersOffset = event?.first ? event?.first : 0;

            let startDate;
            let endDate;
            if(this.competitionOrdersDateRange && this.competitionOrdersDateRange[0] && this.competitionOrdersDateRange[1] ){
                startDate = format(this.competitionOrdersDateRange[0], "yyyyMMdd");
                endDate = format(this.competitionOrdersDateRange[1], "yyyyMMdd");
            } else {
                const today = new Date();
                const tomorrow = add(new Date(), { days: 1 });
                startDate = format(new Date(), "yyyyMMdd");
                endDate = format(tomorrow, "yyyyMMdd");
                this.competitionOrdersDateRange = [];
                this.competitionOrdersDateRange[0] = today;
                this.competitionOrdersDateRange[1] = tomorrow;
            }

            const userId = this.competitionOrdersUser ? this.competitionOrdersUser?.userId : null;
            // console.log("fetchCompetitionTradeOrders", this.competitionOrdersUser);
            
            GroupService.getContestTradeOrders(this.competition.contestId, startDate, endDate, 
                this.competitionOrdersLimit, this.competitionOrdersOffset, userId, this.tradeSortCol, this.tradeSortDir).then(resp => {
                this.competitionOrdersLoading = false;
                if (resp.data.status == "success") {
                    if(resp.data?.orders?.data){
                        this.competitionOrders = getDataFromOrders(resp.data?.orders?.data);
                    } else {
                        this.competitionOrders = null;
                    }
                    this.competitionOrdersTotal = resp.data?.orders?.totalCount;
                }
            });
        },

        fetchCompetitionLongDesc() {
            GroupService.getGroupContest(this.competition.contestId).then(resp => {
                if (resp.data.status == "success") {
                    this.longDescription = resp.data?.contest?.longDescription;
                }
            });
        },

        fetchCompetitionStats() {
            this.competitionStatsTableLoading = true;

            const onResponse = resp => {
                if (resp.data.status == "success") {
                    this.showPrivateMessage = false;
                    // console.log("getCompetitionStats", resp);
                    this.competitionStats = resp.data.stats;

                    this.userStats = this.competitionStats?.userStats ? [this.competitionStats?.userStats] : null;

                    if (this.miniVersion && this.competitionsDetailsLoading && (!this.competitionStats?.allUserStats || this.competitionStats?.allUserStats.length == 0)) {
                        EventBus.emit('view-performance');
                    }
                }
                else if( resp.data.message == 'Not Authorized'){
                    this.showPrivateMessage = true;
                    
                }

                this.competitionStatsTableLoading = false;
                this.competitionsDetailsLoading = false;
            };

            

            GroupService.getContestStats(this.competition.contestId, this.cycle.value.startDate, this.cycle.value.endDate,
                this.competitionStatsLimit, this.competitionStatsOffset, this.selectedSortStatId, (this.selectedGroup? this.selectedGroup.groupId : null)).then(onResponse);
        },

        getRegistrationParam() {
            GroupService.generateGroupContestRegisterParam(this.competition?.hostingGroup?.groupId, this.competition?.contestId).then(resp => {
                if (resp.data.status === 'success') {
                    try {
                        if (navigator.clipboard) {
                            navigator.clipboard.writeText(`${APP_URL}/index.html#/signup?contestId=01110-${resp.data.param}`);
                        }
                        this.$toast.add({ severity: 'success', summary: 'The link  was copied to your clipboard.', life: 2000, group: 'center' });
                    } catch (err) {
                        console.debug('Oops, unable to copy'+err);
                    }
                } else {
                    this.$toast.add({ severity: 'error', detail: resp.data.message, summary: 'Unexpected Error', life: 2000, group: 'center' });
                }
            })
        }

    }
}
</script>

<style>
.p-datepicker table td > span.p-highlight {
    color: white !important;
    background: #32364e !important;
}
</style>

<style scoped>

::v-deep(.p-autocomplete > input, .p-autocomplete > input:focus, .p-autocomplete > input:hover){
    /* min-width:30%; */
    flex-grow:1;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    /* border: 2px solid #32364e; */
    border-right-style: none;
    box-shadow:none !important;
}

/* .clear-group-button:focus{
        outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #b7e0b8;
    border-color: #33CC99;
} */

.clear-group-button{
   
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
    /* border: 2px solid #32364e; */
    border-left-style: none;
    background-color: #fff;
    color:#32364e;
    border:1px solid #ced4da;
}

.level-up-button {
    border: 1px solid #693BF5;
    background: #FFFFFF;
    /* padding: 0; */
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    display: flex;
    margin-left: auto;
    margin-right: auto;
     height: 50%;
    border-radius: 50px;
    margin-left: auto;
}

.level-up_button:hover {
    background: #693BF5;
    border: 1px solid #693BF5;
}


::v-deep(.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus) {

  box-shadow: none;
}



.card {
    margin-bottom: 1rem;
}
.competition-header-row {
    align-items: flex-start;
    margin-bottom: 8px;
}

.competition-header-section {
    flex: 1 1 auto;
}

.competition-icon {
    width: 125px;
}

.registration-link-button {
    height: 50%;
    border-radius: 50px;
    margin-left: auto;
}
.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

::v-deep(table th),
::v-deep(table td) {
    padding: 0.4rem 0.4rem !important;
    white-space: nowrap;
    overflow: hidden;
}

::v-deep(.p-datatable table) {
    width: 100% !important;
}

::v-deep(.ranking-column) {
    width: 10% !important;
    max-width: 10% !important;
}

::v-deep(.username-column) {
    width: 40% !important;
    max-width: 40% !important;
}

::v-deep(.return-column) {
    width: 10% !important;
    max-width: 10% !important;
}

::v-deep(.other-column) {
    width: 20% !important;
    max-width: 20% !important;
}

.username-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.username-label:hover {
    color: #33CC99;
    cursor: pointer;
}

.data-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.your-stats-table-header {
    margin: 10px 0;
}

.all-stats-table-header {
    margin-bottom: 8px;
}

.trade-orders-container {
    display: flex;
    flex-direction: column;
}

.trade-orders-container .p-calendar {
    height: fit-content;
    min-width: 215px;
}

.trade-orders-container .trade-orders-top-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 3px;
}

.trade-orders-container ::v-deep(#transfereeSearchBar) {
    margin-bottom: 0;
}

.trade-orders-container ::v-deep(.transferee-search-bar-container) {
    min-width: 158px;
    width: 0;
    max-width: 400px;
    flex-grow: 1;
    flex-shrink: 1;
}

.trade-orders-container ::v-deep(.p-input-icon-left i) {
    top: 50% !important;
}

.data-container * {
    min-width: 222px;
}

.hosted-by-label {
    color: #33CC99;
}

.hosted-by-label:hover {
    cursor: pointer;
}

.long-description-container {
    max-height: 290px;
    overflow-y: scroll;
    margin-top: 8px;
    white-space: break-spaces;
}

::v-deep(.long-description-container) {
    overflow: auto;
    overflow-y: scroll;
    scrollbar-color: #bfbfbf #f2f2f2;
    scrollbar-width: thin;
}
::v-deep(.long-description-container::-webkit-scrollbar) {
    width: 10px;
}
::v-deep(.long-description-container::-webkit-scrollbar-track) {
    background-color: #f2f2f2;
    opacity: 1;
    transition: background-color 0.2s;
}
::v-deep(.long-description-container::-webkit-scrollbar-thumb) {
    background-clip: padding-box;
    background: #bfbfbf; /* color of the scroll thumb */
    border-radius: 10px; /* roundness of the scroll thumb */
}

@media (max-width: 760px) {

    .registration-link-button {
        margin: 16px 0;
    }

    .competition-header-section {
        flex: 0 1 auto;
    }
    
    .long-description-container {
        max-height: 400px;
    }

    .stats-container {
        padding-top: 8px;
        margin-bottom: 50px;
    }

    .all-stats-table-header {
        margin-top: 8px;
        margin-bottom: 8px;
    }
}


.award-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: underline;
}

.award-span{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
   
}

::v-deep( .award-column ){
    text-align: right;
}

.award-span > img {
     max-width: 10%;
}
@media (max-width: 760px) {
    .award-span > img {
         max-width: 1em;
    }

   ::v-deep( .award-column ){
       
        width: 10% !important;
        max-width: 10% !important;
        text-align: right !important;
    }
}

</style>