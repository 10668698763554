<template>
    <div class="row" v-for="row in lettersGrid" :key="row">
        <div :class="squareClass(col)" v-for="col in row" :key="col.id" @click="selectLetter(col.row, col.col)">
            {{ col.letter.toUpperCase() }}
        </div>
    </div>
</template>

<script>
import EventBus from '../../../event-bus';
import UserService from '../../../service/UserService';

export default {
    name: "PuzzleGameGrid",
    data() {
        return {
            randomWords: ['Beta', 'Stocks', 'Asset', 'CashFlow', 'Balance', 'Price', 'Trade', 'Order', 'Volume', 'Market'],
            possibleLetters: 'abcdefghijklmnopqrstuvwxyz',
            lettersGrid: [],
            size: 15,
            placedWords: [],
            wordsOfTheWeek: null,
            activeAnswer: null,
            puzzleProgress: null
        }
    },
    watch: {
        placedWords: {
            handler(value) {
                for (let i = 0; i < value.length; i++) {
                    let word = value[i];

                    word.completed = word.letters.every(letter => letter.found);
                    if(word.completed) {
                        const foundAnswer = this.puzzleProgress.foundAnswers.find(answer => answer.answerId==word.answerId);

                        if(!foundAnswer){
                            this.puzzleProgress.foundAnswers.push(word);
                            EventBus.emit("puzzle-word-found", word.answerId);
                        }
                        
                    }
                }
            },
            deep: true
        }
    },

    methods: {
        squareClass(col) {
// return {
            //     'square': true,
            //     'black': !col.reserved
            // }

            let completed = false;
            let reserved = false;
            
            for (let i = 0; i < this.placedWords.length; i++) {
                const word = this.placedWords[i];

                if (word.letters.some(letter => letter.id === this.lettersGrid[col.row][col.col].id)) {
                    completed = word.completed;

                    if(word.answerId == this.activeAnswer.answerId){
                        reserved = true;
                    }
                }
            }
            

            return {
                'square': true,
                'reserved': reserved,
                'selected': col.clicked,
                'completed': completed
            }
        },

        initializeGrid(puzzle, activeAnswer, puzzleProgress) {
            this.puzzleProgress = puzzleProgress;
            this.activeAnswer = activeAnswer;
            this.wordsOfTheWeek = puzzle.answers;
            this.lettersGrid = [];

            // Initializes grid of size with random letters 
            for (let i = 0; i < this.size; i++) {
                let row = [];
                for (let j = 0; j < this.size; j++) {
                    row.push({
                        // Randomizing what letter to be chosen for current coordinate
                        letter: this.possibleLetters.charAt(Math.floor(Math.random() * this.possibleLetters.length)),
                        id: ((i * this.size) + j),
                        reserved: false,
                        wordIndex: null,
                        clicked: false,
                        row: i,
                        col: j
                    })
                }

                this.lettersGrid.push(row);
            }

            let placedWordsTempArray = [];

            for (let i = 0; i < this.wordsOfTheWeek.length; i++) {
                const word = this.wordsOfTheWeek[i].answer?.found ? this.wordsOfTheWeek[i].answer?.text 
                    : UserService.decrypt(this.wordsOfTheWeek[i].answer.text);
                
                this.placeWord(word, i, this.wordsOfTheWeek[i].answerId, this.wordsOfTheWeek[i].answer?.found);
                placedWordsTempArray.push(word);
            }

            // Adding random words
            this.randomWords = this.shuffle(this.randomWords);
            let countAddedRandomWords = 0;
            
            for (let i = 0; i < 5; i++) {
                const randomWord = this.randomWords[i];
                if(!placedWordsTempArray.includes(randomWord)){
                    this.placeWord(this.randomWords[i], this.wordsOfTheWeek.length + countAddedRandomWords, null, false);
                    countAddedRandomWords++;
                }
            }
        },

        shuffle(array) {
            const newArray = [...array];
            const length = newArray.length;

            for (let start = 0; start < length; start++) {
                const randomPosition = Math.floor((newArray.length - start) * Math.random());
                const randomItem = newArray.splice(randomPosition, 1);

                newArray.push(...randomItem);
            }

            return newArray;
        },

        selectLetter(x, y) {
            
            if (this.lettersGrid[x][y].reserved === true) {

                //for (let i = 0; i < this.placedWords.length; i++) { // todo only active word
                    // let word = this.placedWords[i];
                    
                    let word = this.placedWords.find(word=> word.isActiveAnswer);

                    if (word.letters.some((letter => letter.id === this.lettersGrid[x][y].id))) {
                        if (!word.letters.every((letter => letter.found))) {
                            for (let j = 0; j < word.letters.length; j++) {
                                if (this.lettersGrid[x][y].id === word.letters[j].id && !word.letters[j].found) {
                                    word.letters[j].found = true;
                                    this.lettersGrid[x][y].clicked = true;
                                }
                            }
                        }
                    }
                //}
            }
        },

        placeWord(word, index, answerId, found) {
            // remove spaces
            word = word.replace(/\s+/g, '');

            let collision, start, end, direction, i, x, y, constPosition;

            /* We check to see if we can safely place the word without colliding into other reserved letters at all */

            do {
                // Determines if placing current letter of word collides with current letter on grid that is already reserved (placed)
                collision = false;

                let length = word.length;

                // Used to randomly dictate if we want to place the word vertically or horizontally
                direction = (Math.floor(Math.random() * 2) == 0) ? true : false;

                // Highest start position to place word without crossing the grid edges
                let startEnd = this.size - length;

                // Randomizing starting position of placing whole world without crossing the grid edges
                start = Math.floor(Math.random() * startEnd);

                // Ending position of placing whole word from random starting position
                end = start + length;

                // Used for constant x coordinate if going vertically or constant y coordinate if going horizontally
                constPosition = Math.floor(Math.random() * this.size);

                for (i = start; i < end; i++) {
                    x = direction ? i : constPosition;
                    y = direction ? constPosition : i;

                    if (this.lettersGrid[x][y].reserved) { // If traversed letter has already been placed by a letter from a word
                        collision = true;
                    }
                }
            } while (collision); // If there was a collision, we repeat the same process until we can find coordinates to place the word without any collisions at all

            // We keep track of the words that have been placed for the user 
            this.placedWords.push({ word: word, letters: [], completed: found, isActiveAnswer: answerId === this.activeAnswer.answerId, answerId:answerId })

            /* If we found some unreserved coordinates to place all the letters of our chosen word from, we repeat
            the same process as earlier but we actually replace the letters of the unreserved coordinates of all the
            letters of our chosen word */

            for (i = start; i < end; i++) {
                x = direction ? i : constPosition;
                y = direction ? constPosition : i;

                this.lettersGrid[x][y].letter = word[i - start]; // Assigning targeted coordinate with letter of chosen word
                this.lettersGrid[x][y].reserved = true; // We reserve the coordinate so that other letters from other words won't be placed there
                this.lettersGrid[x][y].clicked = found;

                if (this.lettersGrid[x][y].reserved && i - start === 0) {
                    this.lettersGrid[x][y].wordIndex = index + 1;
                    this.lettersGrid[x][y].clicked = found;
                }

                this.placedWords[this.placedWords.length - 1].letters.push({ id: this.lettersGrid[x][y].id, found: found });
                
            }
        }
    }
};
</script>

<style scoped>
.row {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
}

.square {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    aspect-ratio: 1/1;
    color: #323632;
    font-size: 16px;
    font-weight: bold;
    background: #FFF;
    border: 2px solid #32364e;
    border-radius: 6px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
}

.square:hover {
    cursor: pointer;
    background: #32364e;
    color: #FFF;
}

.square:not(.selected, .reserved):active {
    transform: rotate(0.2deg);
    transition: transform 0.25s cubic-bezier(0.5, 400, 0.5, -400);
    border-color: #E63E3E;
    background: #E63E3E;
}

.square.selected {
    border-color: #33CC99;
    background: #33CC99;
    color: #FFF;
    pointer-events: none;
}

.square.completed {
    animation: completed-pulse 2s infinite;
}

/* .square.black {
    background: black;
    border-color: black;
}
.square:not(.black):hover {
    cursor: pointer;
    background: #32364e;
    color: #FFF;
}

.word-index {
    position: absolute;
    font-size: 11px;
    top: 2px;
    left: 2px;
} */

.row>.square {
    margin-right: 8px;
}

.row>.square:last-child {
    margin-right: 0;
}

@keyframes completed-pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(51, 204, 153, 0.8);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 12px rgba(51, 204, 153, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(51, 204, 153, 0);
    }
}

@media (max-width: 760px) {
    .square {
        font-size: 15px; 
        border-radius: 6px !important;
    }

    .row>.square {
        margin-right: 4px;
    }

}
</style>