<template>
    <span v-if="canAward && isGroupOwnerAdminOrMod" class="award-label" @click='issueParticipationAward()' style="margin-left: auto;">
                   Issue Participation Awards
                </span>
    <DataTable lazy paginator scrollable class="p-datatable-sm" p-datatable-sm :rows="competitionStatsLimit"
        :value="allUserStats" @page="onPage($event)" :totalRecords="totalStats" :loading="showLoading">
        <Column field="ranking" header="#" class="ranking-column" />
        <Column header="Name" class="username-column">
            <template #body="slotProps">
                <span class="username-label" @click='onContestantClick(slotProps.data)'
                    v-tooltip.bottom="'@' + getContestantName(slotProps.data.analyst)">
                    @{{ getContestantName(slotProps.data.analyst) }}
                </span>
            </template>
        </Column>
        <Column class="return-column" header="%">
            <template #body="slotProps">
                {{ formatter.format(slotProps.data?.returnValue * 100, '0.00') }}
            </template>
        </Column>
        <Column class="other-column" :header="(isMobile() ? '$S' : '$Start')">
            <template #body="slotProps">
                {{ formatter.format(slotProps.data.startingValue, '$0.00') }}
            </template>
        </Column>
        <Column class="other-column" :header="(isMobile() ? '$C' : '$Current')">
            <template #body="slotProps">
                {{ formatter.format(slotProps.data.currentValue, '$0.00') }}
            </template>
        </Column>
         <Column class="award-column" :header="(isMobile() ? '&nbsp;&nbsp;A' : 'Actions')" v-if="isGroupOwnerAdminOrMod" >
            <template #body="slotProps">
                
                    <template v-if="canAward">
                        <span v-if="!slotProps.data.award" class="award-label" @click='onGiveAward(slotProps.data)'>
                        Issue Award
                        </span>
                        <span v-else class="award-span" @click='onRemoveAward(slotProps.data)'
                            v-tooltip.bottom="getAwardName(slotProps.data.award)">
                            <img :src="getAwardIcon(slotProps.data.award)" />
                        
                        </span>
                    </template>
                    <template v-else>
                        <span v-if="slotProps.data.award" class="award-span" 
                            v-tooltip.bottom="getAwardName(slotProps.data.award)">
                            <img :src="getAwardIcon(slotProps.data.award)" />
                        
                        </span>
                    </template>
                    
                        <Button class="green-button__secondary remove-button" icon="pi pi-times" @click="removeFromCompetition(slotProps.data)" v-tooltip.bottom="'Remove from competition.'"/>
                       
                    
               
            </template>
        </Column>
        <AwardModal ref="awardModal" @confirmed="onAwardConfirmed" @cancelled="onAwardCancelled"/>
        <ConfirmModal ref="confirmRemoveAwardModal" @confirmed="onRemoveAwardConfirmed" :headerText="'Remove Award?'" />
        <ConfirmModal ref="confirmIssueModal" @confirmed="onIssueConfirmed" :headerText="'Issue Participation Awards?'" />
        <ConfirmModal ref="confirmRemoveModal" @confirmed="onRemoveConfirmed" :headerText="'Remove Contestant'" :cancelButtonLabel="'No'" :confirmButtonLabel="'Yes'">
            <template #content>
                <div style="display:block;padding:10px;">
                    <p><strong>Are you sure you want to remove {{selectedAnalyst.analyst.name}} from the competition?</strong></p>
                    
                </div>
            </template>
        </ConfirmModal>
    </DataTable>
</template>

<script>
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import AwardModal from './AwardModal';
import FormattingUtils from '../../../utilities/FormattingUtils';
import ConfirmModal from '../../modal/ConfirmModal.vue';
import {AWARDS} from '../index';
import GroupService from '../../../service/GroupService';
import BrowserUtils from '../../../utilities/BrowserUtils';
import UserService from '../../../service/UserService';

export default {
    name: 'CompetitionStatsTable',
    emits: ['load-page', 'contestant-clicked'],
    props: {
        miniVersion: {
            type: Boolean,
            required: false,
            default: false
        },
        competition: {
            type: Object,
            required: true
        },
        competitionStatsLimit: {
            type: Number,
            required: true
        },
        totalStats: {
            type: Number,
            required: true
        },
        allUserStats: {
            type: Object,
            required: true
        },
        loading: {
            type: Boolean,
            required: true
        },
        canAward: {
            type: Boolean,
            required: false,
            default: false
        },
        isGroupOwnerAdminOrMod:{
            type: Boolean,
            required: false,
            default: false
        }
    },
    components: {
        DataTable,
        Column,
        AwardModal,
        ConfirmModal
    },
    data() {
        return {
            formatter: new FormattingUtils(),
            selectedAnalyst: null,
            currentOffset: 0,
            updating: false,
        }
    },

    computed: {
        showLoading() {
            return this.loading || this.updating;
        }
    },

    mounted() {
        this.updating = false;
    },

    methods: {
       isMobile() {
            return BrowserUtils.isMobile();
        },

        onIssueConfirmed() {
            this.updating = true;
            GroupService.issueParticipationAwards(this.competition.groupId, this.competition.contestId).then(resp=> {
                if( resp.data.status == 'success') {
                    this.$emit('load-page', this.currentOffset);
                }
                this.updating = false;
            })
        }, 

        issueParticipationAward() {
            this.$refs.confirmIssueModal.open();
        },
        getAwardName(awardId) {
            for( const a of AWARDS ) {
                if( a.awardId == awardId){
                    return a.name;
                }
            }
            return ""; 
        },
        getAwardIcon(awardId) {
           for( const a of AWARDS ) {
                if( a.awardId == awardId){
                    return a.icon;
                }
            }
            return ""; 
        },
        awardName(awardId){
            for( const a of AWARDS ) {
                if( a.awardId == awardId){
                    return a.name;
                }
            }
            return "Unknown award";
        },
        onRemoveAwardConfirmed() {
            GroupService.stripAward(this.competition.groupId, this.competition.contestId,this.selectedAnalyst.userId).then(resp=> {
                if( resp.data.status == 'success') {
                    this.$emit('load-page', this.currentOffset);
                }
            })
        },

        onAwardConfirmed(award) {
           
            GroupService.awardContestant(this.competition.groupId, this.competition.contestId, award.awardId, this.selectedAnalyst.userId, award.awardReason).then(resp=> {
                if( resp.data.status == 'success') {
                    this.$emit('load-page', this.currentOffset);
                }
            })
        },

        onAwardCancelled() {

        },

        onGiveAward(analyst) {
            this.selectedAnalyst = analyst;
            this.$refs.awardModal.open();
        },

        onRemoveAward(analyst) {
            this.selectedAnalyst = analyst;
            this.$refs.confirmRewardRemoveModal.open();
        },

        removeFromCompetition(contestant) {
            //console.log("removeing ", analyst);
            this.selectedAnalyst = contestant;
            this.$refs.confirmRemoveModal.open();
        },

        onRemoveConfirmed(){
            UserService.removeFromContest(this.competition, this.selectedAnalyst).then(resp => {
                if( resp.data.status == 'success'){
                    this.$emit('load-page', this.currentOffset);
                }
            }).catch(err => {
                console.log("error removing user from contest", err);
            });
        },


        onPage(event) {
            const offset = event.page * event.rows;
            console.log(event, offset);
            this.$emit('load-page', offset);
            this.currentOffset = offset;
        },

        onContestantClick(item) {
            if( !this.analystDeleted(item.analyst) ) {
                this.$emit('contestant-clicked', item)
            }
        },

        analystDeleted(analyst) {
            
            return analyst.name.endsWith("-"+analyst.analystId);
        },

        getContestantName(analyst) {
            if( this.analystDeleted(analyst)){
                return "xxxxxx";
            }
            else {
                return analyst.name;
            }
        }
    }
}
</script>

<style scoped>

.remove-button{
    width: 0.5rem;
    padding: 0.5rem 1rem;
   
    border-color:red;
    margin-left:auto;
}

.remove-button:hover{
    background-color:red;
    border-color:red;
}

::v-deep(.remove-button > span) {
    
    font-size: 9px !important;
}

::v-deep(table th),
::v-deep(table td) {
    padding: 0.4rem 0.4rem !important;
}

.username-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.award-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: underline;
}

.award-span{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
   
}

::v-deep( .award-column ){
    text-align: right;
}

.award-span > img {
     max-width: 10%;
}
@media (max-width: 760px) {
    .award-span > img {
         max-width: 1.5em;
         max-height:1.5em;
    }

   ::v-deep( .award-column ){
       
        width: 10% !important;
        max-width: 10% !important;
        text-align: right !important;
    }
}

.award-label:hover {
    color: #33CC99;
    cursor: pointer;
}

.username-label:hover {
    color: #33CC99;
    cursor: pointer;
}

.column-header:hover {
    color: #33CC99;
    cursor: pointer;
}

.column-header-selected {
    color: #33CC99;
}

.column-icon {
    margin-left: 2px;
}

::v-deep(.p-paginator .p-paginator-pages .p-paginator-page) {
    color: #32364e;
}
::v-deep(.p-paginator-first:not(.p-disabled):not(.p-highlight):hover),
::v-deep(.p-paginator-prev:not(.p-disabled):not(.p-highlight):hover),
::v-deep(.p-paginator-next:not(.p-disabled):not(.p-highlight):hover),
::v-deep(.p-paginator-last:not(.p-disabled):not(.p-highlight):hover),
::v-deep(.p-paginator .p-paginator-pages .p-paginator-page.p-highlight),
::v-deep(.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover) {
    border-color: #32364e;
    background: #32364e;
    color: #FFFFFF;
}
::v-deep(.p-paginator-first:focus),
::v-deep(.p-paginator-prev:focus),
::v-deep(.p-paginator-next:focus),
::v-deep(.p-paginator-last:focus),
::v-deep(.p-paginator .p-paginator-pages .p-paginator-page.p-highlight:focus) {
    box-shadow: none;
}
</style>