<template>
    <div class="competitions-list-container">
        <div>
            <template v-for="competition in competitions" :key="competition.contestId">
                <div class="card" >

                    <div v-if="isMobile" class="flex-column">
                        <div class="flex-row">
                            <UserAvatar :imageSrc="competition?.iconUrl" size='lg' shape='roundedSquare'/>
                            <div class="flex-column" style="gap: 8px; padding: 0px 16px 16px;">
                                <span style="font-size: 20px;">
                                    {{ competition?.name }}
                                </span>
                                
                                <div>
                                    <span>Hosted by: </span>
                                    <span class="hosted-by-label" @click.stop='goToGroupProfile(competition?.hostingGroup?.groupId)'>
                                        {{ competition?.hostingGroup?.name }}
                                    </span>
                                </div>
                                <span v-if="competition?.registered">You are a registered contestant</span>
                                <span v-if="canAward(competition)">🏁This competition has ended.</span>
                                <Button @click='goToCompetitionsDetails(competition)' class="green-button__secondary action-button">
                                    <span>Open</span>
                                </Button>
                                <Button v-if="showRegisterForCompetitionButton(competition)" 
                                    class="green-button__secondary action-button" label="Register" @click="confirmCompetitionDetails(competition)"/>
                            </div>
                        </div>
                        <table>
                            <tr>
                                <td>Registration Start: </td>
                                <td>{{ getCompetitionDateFormatted(competition?.registrationStartDate) }}</td>
                            </tr>
                            <tr>
                                <td>Competition Start: </td>
                                <td>{{ getCompetitionDateFormatted(competition?.startDate) }}</td>
                            </tr>
                            <tr>
                                <td>Competition End: </td>
                                <td>{{ getCompetitionDateFormatted(competition?.endDate) }}</td>
                            </tr>
                            <tr>
                                <td>Type: </td>
                                <td>{{ getCompetitionType(competition?.competitionTypeId) }}</td>
                            </tr>
                            <tr>
                                <td>Registration: </td>
                                <td>{{ getCompetitionRegistrationType(competition) }}</td>
                            </tr>
                            <tr>
                                <td>Skill Level: </td>
                                <td>{{ getCompetitionSkillLevel(competition?.skillLevelId) }}</td>
                            </tr>
                            <tr>
                                <td>Competition Type: </td>
                                <td>{{ getCompetitionCategory(competition?.categoryId) }}<span v-if="competition?.openPortfolio"> (Open)</span></td>
                            </tr>
                            <tr>
                                <td>Private: </td>
                                <td >
                                    {{competition.privateContest}}
                                    
                                </td>
                            </tr>
                            <tr  v-if="competition.issuePass">
                                <td colspan="2">This competition includes a Basic Investor Pass to avoid usage limits.
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div v-else class="flex-row">
                        <div class="flex-row" style="width: 40%;">
                            <UserAvatar :imageSrc="competition?.iconUrl" size='lg' shape='roundedSquare'/>
                            <div class="flex-column" style="gap: 8px; padding: 0px 16px 0px;">
                                <span style="font-size: 20px;">
                                    {{ competition?.name }}
                                </span>
                                <div>
                                    <span>Hosted by: </span>
                                    <span class="hosted-by-label" @click.stop='goToGroupProfile(competition?.hostingGroup?.groupId)'>
                                        {{ competition?.hostingGroup?.name }}
                                    </span>
                                </div>
                                <span v-if="competition?.registered">You are a registered contestant</span>
                                <span v-if="canAward(competition)">🏁This competition has ended.</span>
                                <Button @click='goToCompetitionsDetails(competition)' class="green-button__secondary action-button">
                                    <span>Open</span>
                                </Button>
                                <Button v-if="showRegisterForCompetitionButton(competition)" 
                                    class="green-button__secondary action-button" label="Register" @click="confirmCompetitionDetails(competition)"/>
                            </div>
                        </div>
                        <div class="data-container" style="width: 60%;">
                            <div>
                                <span>Registration Start: </span>
                                <span>{{ getCompetitionDateFormatted(competition?.registrationStartDate) }}</span>
                            </div>
                            <div>
                                <span>Registration End: </span>
                                <span>{{ getCompetitionDateFormatted(competition?.registrationEndDate) }}</span>
                            </div>
                            <div>
                                <span>Competition Start: </span>
                                <span>{{ getCompetitionDateFormatted(competition?.startDate) }}</span>
                            </div>
                            <div>
                                <span>Competition End: </span>
                                <span>{{ getCompetitionDateFormatted(competition?.endDate) }}</span>
                            </div>
                            <div>
                                <span>Type: </span>
                                <span>{{ getCompetitionType(competition?.competitionTypeId) }}</span>
                            </div>
                            <div>
                                <span>Registration: </span>
                                <span>{{ getCompetitionRegistrationType(competition) }}</span>
                            </div>
                            <div>
                                <span>Skill Level: </span>
                                <span>{{ getCompetitionSkillLevel(competition?.skillLevelId) }}</span>
                            </div>
                            <div>
                                <span>Competition Type: </span>
                                <span>{{ getCompetitionCategory(competition?.categoryId) }}<span v-if="competition?.openPortfolio"> (Open)</span></span>
                            </div>
                            <div>
                                <span>Private: </span>
                                <span>
                                {{competition.privateContest}}
                                   </span> 
                            </div>
                             <div v-if="competition.maxContestants">
                                <span>Max Contestants: </span>
                                <span>
                                {{competition.maxContestants}}
                                   </span> 
                               
                            </div>
                            <div v-if="competition.issuePass">
                                <span>This competition includes a Basic Investor Pass to avoid usage limits.</span>
                               
                            </div>

                        </div>
                        <img class="competition-list-icon" src="assets/images/competition.png" v-if="!isMobile"/>
                    </div>

                </div>
            </template>
             <Paginator :rows="limit" :first="offset" :totalRecords="total" @page="onPage"/>
        </div>
       
        
    </div>

    
    <RegCodeModal  ref="regCodeModal" @confirmed="onRegConfirmed" @cancelled="onRegCancelled"/>
    <ConfirmModal headerText="Confirm Registration" ref="confirmDetailsModal" style="max-width:500px;" @confirmed="onDetailsConfirmed" :confirmButtonGreen="true">
        <template #content>
            <div style="display:block;padding:15px;">
                <div><p>You are registering for <b>{{selectedCompetition.name}}</b>.</p>
                <p>
                    Please review the competition details below including all rules, disclaimers and eligibility requirements before registering.
                </p>
                <p><b>Competition Details:</b></p>
                </div>
                <div class="long-description-container">
                    
                   <p> {{selectedCompetition.longDescription}}</p>
                </div>
                    
                
                <div style="margin-left:auto;margin-right:auto;text-align:center;">
                    <p></p>
                    <p><b>Continue with registration?</b></p>
                     <p></p>
                </div>
            </div>
        </template>
    </ConfirmModal>
</template>

<script>
// import Column from 'primevue/column';
// import DataTable from 'primevue/datatable';
import UserAvatar from "../../profile/UserAvatar.vue";
import Paginator from 'primevue/paginator';
// import router from '../../../router';
import { getCompetitionCategory, getCompetitionSkillLevel, getCompetitionRegistrationType, getCompetitionDateFormatted, getCompetitionType } from '../index';
import BrowserUtils from "../../../utilities/BrowserUtils";
import UserService from '../../../service/UserService';
import EventBus from '../../../event-bus';
import { isAfter, isBefore, startOfDay, add } from 'date-fns';
import RegCodeModal from './RegCodeModal.vue';

import ConfirmModal from '../../modal/ConfirmModal.vue';

export default {
    name: 'CompetitionsList',
    emits: ['select-comp', 'change-page'],
    props: {
        loading: {
            type: Boolean,
            required: true
        },
        competitions: {
            type: Array,
            required: false
        },

        limit: {
            type: Number,
            required: true
        },

        offset: {
            type: Number,
            required: true
        },

        total: {
            type: Number,
            required: true
        }
    },
    components: {
        UserAvatar,
        RegCodeModal,
        Paginator,
        ConfirmModal,
    },
    data() {
        return {
            getCompetitionCategory, getCompetitionSkillLevel,
            getCompetitionRegistrationType, getCompetitionDateFormatted,
            getCompetitionType,
            selectedCompetition: null,

        }
    },

    computed: {
        isMobile() {
            return BrowserUtils.isMobile();
        },
    },

    methods: {

        onPage( pageState){
            this.$emit('change-page', pageState);
        },
        canAward(competition){
            const now = new Date();
            var b = isAfter(now, startOfDay(add(new Date(competition?.endDate), { days: 1 })));
            
            return b ;
        },

        goToCompetitionsDetails(item) {
            //this.$router.push('/contests/competitions/' + item.contestId);
            this.$emit('select-comp', item);
        },

        goToGroupProfile(groupId) {
            this.$router.push('/groups/profile/' + groupId);
        },

        showRegisterForCompetitionButton(competition) {
            if ((competition?.inviteOnly && !competition.registrationCode)|| competition?.registered) {
                return false;
            }
            const today = new Date();
            const registrationStartDate = new Date(competition?.registrationStartDate);
            const registrationEndDate = new Date(competition?.registrationEndDate);
            // console.log("showRegisterForCompetitionButton",isAfter(today, registrationStartDate), isBefore(today, registrationEndDate));
            return isAfter(today, registrationStartDate) && isBefore(today, registrationEndDate);
        },

        onRegConfirmed(code) {
            if( code && code != '' ) {
                UserService.registerForContest(this.$store.state.users.user.userId, this.selectedCompetition?.contestId, code).then(resp => {
                    if (resp.data.status === 'success') {
                        EventBus.emit('refresh-competitions');
                         EventBus.emit('track-join-competition', this.selectedCompetition);
                        this.$router.push(`/contests/competitions/${this.selectedCompetition?.contestId}?justRegistered=true`);
                    } else {
                        this.$toast.add({ severity: 'error', summary: 'Contest Registration Failed!', detail: resp.data.message, life: 8000, group: 'center' });
                    }
                    this.selectedCompetition = null;
                }).catch(err => {
                    console.err(err);

                    this.$toast.add({ severity: 'error', summary: 'Contest Registration Failed! Contact support if the problem persists.', life: 8000, group: 'center' });
                    this.selectedCompetition = null;
                });
            }
        },

        onRegCancelled() {
            this.selectedCompetition = null;
        },

        confirmCompetitionDetails(competition) {
            this.selectedCompetition = competition;
            this.$refs.confirmDetailsModal.open();
        },

        onDetailsConfirmed() {
            this.registerForCompetition(this.selectedCompetition);
        },


        registerForCompetition(competition) {

            if( competition.registrationCode ) {
                //this.selectedCompetition = competition;
                this.$refs.regCodeModal.open();
            }
            else {

                UserService.registerForContest(this.$store.state.users.user.userId, competition?.contestId).then(resp => {
                    if (resp.data.status === 'success') {
                        EventBus.emit('refresh-competitions');
                        EventBus.emit('track-join-competition', competition);
                        this.$router.push(`/contests/competitions/${competition?.contestId}?justRegistered=true`);
                    } else {
                        this.$toast.add({ severity: 'error', summary: 'Contest Registration Failed!', detail: resp.data.message, life: 8000, group: 'center' });
                    }
                    
                }).catch(err => {
                    console.err(err);

                    this.$toast.add({ severity: 'error', summary: 'Contest Registration Failed!  Contact support if the problem persists.', life: 8000, group: 'center' });
                });
            }
        },
    }
}
</script>

<style scoped>
.competition-list-icon {
    max-width: 5em;
    max-height: 5em;
}
.competitions-list-container {
    display: flex;
    margin: 1rem 1rem 60px 1rem;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

::v-deep(table th),
::v-deep(table td) {
    padding: 0.4rem 0.4rem !important;
}

.hosted-by-label {
    color: #33CC99;
}

.hosted-by-label:hover {
    cursor: pointer;
}

.data-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.data-container * {
    min-width: 222px;
}

.action-button {
    padding: 8px 16px;
    border-style: solid;
    border-radius: 28px;
    text-align: center;
    width: 200px;
}

.action-button span {
    width: 100%;
}

@media (max-width: 760px) {
    .competitions-list-container {
        margin-left: 1rem;
    }
}

.long-description-container {
    max-height: 290px;
    overflow-y: scroll;
    margin-top: 8px;
    white-space: break-spaces;
}

::v-deep(.long-description-container) {
    overflow: auto;
    overflow-y: scroll;
    scrollbar-color: #bfbfbf #f2f2f2;
    scrollbar-width: thin;
}
::v-deep(.long-description-container::-webkit-scrollbar) {
    width: 10px;
}
::v-deep(.long-description-container::-webkit-scrollbar-track) {
    background-color: #f2f2f2;
    opacity: 1;
    transition: background-color 0.2s;
}
::v-deep(.long-description-container::-webkit-scrollbar-thumb) {
    background-clip: padding-box;
    background: #bfbfbf; /* color of the scroll thumb */
    border-radius: 10px; /* roundness of the scroll thumb */
}
</style>