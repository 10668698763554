<template>
    <div class="container">
        <div class="card">
            <div class="answer-details-container">
                <div style="flex-grow: 8;">
                    <span class="details-span">Puzzle Name: {{ selectedContestPuzzle.name }}</span>
                    <span class="details-span">Description: {{ selectedContestPuzzle?.description }}</span>
                    <span class="details-span">Start And End Dates: {{ startAndEndDates }}</span>
                </div>
                <div style="flex-grow: 1;">
                    <span class="details-span">Reward Amount: {{ selectedContestPuzzle.rewardAmount }}</span>
                    <span class="details-span">Reward Currency: {{ currencyText }}</span>
                    <span class="details-span">Published Date: {{ selectedContestPuzzle?.publishedDate }}</span>
                </div>
                <div class="actions-buttons">
                    <Button label="Edit" @click="openCreateEditPuzzleModal" class="green-button__secondary" />
                </div>
            </div>
        </div>

        <div class="card">
            <div class="answers-header">
                <span>Answers</span>
                <Button label="Add Answer" @click="openCreateEditAnswerModal" class="green-button__secondary" />
            </div>
            <DataTable v-if="puzzleAnswers?.length > 0" scrollable scrollHeight="flex" @rowReorder="onAnswersReorder"
                :value="puzzleAnswers" class="p-datatable-sm">
                <Column rowReorder class="row-reorder-column" />
                <Column style="width: 100%;  padding: 8px 0 8px;">
                    <template #body="puzzleAnswer">
                        <PuzzleEditAnswerItem :selectedContest="selectedContest" :answer="puzzleAnswer.data" 
                            @openCreateEditAnswerModal="openCreateEditAnswerModal(e, puzzleAnswer.data)" 
                            @open-delete-modal="openConfirmDeleteModal"/>
                    </template>
                </Column>
            </DataTable>
        </div>

    </div>
    <CreateEditPuzzleAnswerModal ref="createEditPuzzleAnswerModal" :selectedContest="selectedContest"
        :selectedPuzzle="selectedContestPuzzle" />
    <CreateEditPuzzleModal ref="createEditPuzzleModal" :selectedContest="selectedContest" />
    <ConfirmModal :headerText="confirmDeleteModalText" ref="confirmDeleteModal" @confirmed="onDeleteConfirmed"/>
</template>

<script>
import { mapState } from 'vuex';
import PuzzleService from '../../../../service/PuzzleService';

import DataTable from 'primevue/datatable';
import CreateEditPuzzleAnswerModal from './CreateEditPuzzleAnswerModal';
import Column from 'primevue/column';
import PuzzleEditAnswerItem from './PuzzleEditAnswerItem.vue';
import TimeUtils from '../../../../utilities/TimeUtils';
import CreateEditPuzzleModal from './CreateEditPuzzleModal.vue';
import ConfirmModal from '../../../modal/ConfirmModal.vue';
import EventBus from '../../../../event-bus';
import { getCurrencyText } from '../../index';

export default {
    name: "PuzzleEdit",
    props: {
        selectedContest: {
            type: Object,
            required: false
        },
    },
    data() {
        return {
            puzzleAnswers: null,
            confirmDeleteModalText: "",
            deleteData: null,
        };
    },
    components: {
        CreateEditPuzzleAnswerModal,
        DataTable,
        Column,
        PuzzleEditAnswerItem,
        CreateEditPuzzleModal,
        ConfirmModal
    },
    computed: {
        ...mapState(['selectedContestPuzzle']),

        startAndEndDates() {
            return TimeUtils.getFormattedTime(this.selectedContestPuzzle.startDate, 'YYYY/MM/DD')
                + ' - ' + TimeUtils.getFormattedTime(this.selectedContestPuzzle.endDate, 'YYYY/MM/DD');
        },

        currencyText() {
            return getCurrencyText(this.selectedContestPuzzle.currencyId);
        },
    },
    mounted() {
        this.fetchPuzzleAnswers();

        EventBus.off('refresh-puzzles-answers-list');
        EventBus.on('refresh-puzzles-answers-list', this.fetchPuzzleAnswers);
    },
    methods: {
        fetchPuzzleAnswers() {
            PuzzleService.getPuzzleAnswers(this.selectedContest.contestId, this.selectedContestPuzzle.puzzleId).then(resp => {
                if (resp.data.status == 'success') {
                    this.puzzleAnswers = resp.data.answers;
                    // console.log("getPuzzleAnswers", this.puzzleAnswers);
                }
            });
        },

        onAnswersReorder(event) {
            this.puzzleAnswers = event.value;
            const puzzleAnswersIds = this.puzzleAnswers.map(a => a.answerId);
            // console.log("updatePuzzleAnswerHintOrdering", event.value, puzzleAnswersIds);
            PuzzleService.updatePuzzleAnswerOrdering(
                this.selectedContest.contestId, this.selectedContestPuzzle.puzzleId, puzzleAnswersIds)
                .then(resp => {
                if (resp.data.status == "success") {
                    console.log("updatePuzzleAnswerOrdering", resp);
                }
            });
        },

        openCreateEditAnswerModal(e, answer) {
            const lastAnswer = this.puzzleAnswers[this.puzzleAnswers?.length-1];
            this.$refs.createEditPuzzleAnswerModal.open(answer, lastAnswer);
        },

        openCreateEditPuzzleModal() {
            this.$refs.createEditPuzzleModal.open(this.selectedContestPuzzle);
        },

        openConfirmDeleteModal(data) {
            this.deleteData = data;

            if(this.deleteData?.answer) {
                this.confirmDeleteModalText = "Delete answer: " + this.deleteData?.answer?.answer?.text;
            } else if(this.deleteData?.hint) {
                this.confirmDeleteModalText = "Delete hint: " + this.deleteData?.hint?.hint?.text;
            } 

            this.$refs.confirmDeleteModal.open();
        },

        onDeleteConfirmed() {
            if(this.deleteData?.answer) {
                PuzzleService.deletePuzzleAnswer(this.selectedContest.contestId, this.selectedContestPuzzle.puzzleId, this.deleteData?.answer?.answerId).then(resp => {
                    if (resp.data.status == 'success') {
                        this.fetchPuzzleAnswers();
                        console.log("deletePuzzleAnswer", resp.data);
                        this.$toast.add({ severity: 'success', summary: 'Deleted answer!', detail: '', life: 1000, group: 'center' });
                    }
                });
            } else if(this.deleteData?.hint) {
                const answerId = this.deleteData?.hint.answerId;
                PuzzleService.deletePuzzleAnswerHint(this.selectedContest.contestId, 
                    this.selectedContestPuzzle.puzzleId, this.deleteData?.hint.answerId, 
                    this.deleteData?.hint.hintId).then(resp => {
                    if (resp.data.status == 'success') {
                        EventBus.emit('refresh-puzzle-answer-hints', answerId);
                        console.log("deletePuzzleAnswerHint", resp.data);
                        this.$toast.add({ severity: 'success', summary: 'Deleted answer!', detail: '', life: 1000, group: 'center' });
                    }
                });
            }

            this.deleteData = null;
            this.confirmDeleteModalText = "";
        },
    }
};
</script>

<style scoped>
.container {
    margin: 16px;
}

.answer-details-container {
    display: flex;
    flex-direction: row;
    gap: 16px;
}

.answer-details-container div {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.answers-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
}

.details-span {
    display: block;
}

.actions-buttons {
    justify-content: flex-end;
    display: flex;
    flex-direction: row !important;
    align-items: center;
}

::v-deep(.row-reorder-column) {
    width: 2rem;
    max-width: 2rem;
    align-items: unset !important;
    padding: 16px 0 0 !important;
}

::v-deep(.p-datatable-thead) {
    display: none !important;
}
</style>