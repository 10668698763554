<template>
    <div v-if="selectedContest && isMobile && cycleOptions">
        <div class="card">
            <div class="content-cycle-container">
                <span>Cycle:</span>
                <Dropdown :options="cycleOptions" optionLabel="label" v-model="selectedCycleOption" />
            </div>

            <TabMenu :model="statisticsAndQuestionsItems" :activeIndex="statisticsAndQuestionsActiveIndex"
                style="position:unset;">
                <template #item="{ item }">
                    <button @click="viewStatisticsAndQuestions(item.label)">
                        {{ item.label }}
                    </button>
                </template>
            </TabMenu>
        </div>
        <template v-if="selectedCycleOption">
            <div class="card" v-if="statisticsAndQuestionsActiveIndex == 0">
                <ContestStats miniVersion :contest="selectedContest" :cycle="selectedCycleOption" />
            </div>
            <FeedActivityList v-else :selectedContestData="contestFeedData" scrollStyle='width: 100%; height: calc(72vh)' />
        </template>
    </div>

    <div v-else-if="selectedContest && cycleOptions">
        <div class="container">
            <div class="p-md-7">
                <ContestStats v-if="selectedCycleOption" :contest="selectedContest" :cycle="selectedCycleOption" />
            </div>

            <div class="p-md-5">
                <div class="card content-cycle-container">
                    <span>Cycle:</span>
                    <Dropdown :options="cycleOptions" optionLabel="label" v-model="selectedCycleOption" />
                </div>

                <template v-if="contestFeedData">
                    <PillMenuBar :navigationItems="feedMenuItems" headerType="sub" />

                    <FeedActivityList :selectedContestData="contestFeedData"
                        scrollStyle='width: 100%; height: calc(100vh - 235px)' />
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import ContestStats from './ContestStats.vue';
import PillMenuBar from '../../menu/PillMenuBar.vue';
import FeedActivityList from '../../feed/FeedActivityList.vue';
import Dropdown from 'primevue/dropdown';
import TabMenu from 'primevue/tabmenu';

import { getContestCycles } from '../index';
import BrowserUtils from '../../../utilities/BrowserUtils';

const statisticsAndQuestionsLabels = {
    statistics: 'Statistics',
    questions: 'Questions'
}

export default {
    name: 'ContestHome',
    props: {
        selectedContest: {
            type: Object,
            required: false
        },
    },
    components: {
        ContestStats,
        PillMenuBar,
        FeedActivityList,
        Dropdown,
        TabMenu
    },
    data() {
        return {
            contestsLoading: true,
            contests: [],

            contestsMenuItems: [],
            feedMenuItems: [
                { label: 'Questions', action: () => console.log("hi"), selected: true },
            ],

            selectedCycleOption: null,
            cycleOptions: [],

            statisticsAndQuestionsActiveIndex: 0,
            statisticsAndQuestionsItems: [
                { index: 0, label: statisticsAndQuestionsLabels.statistics, selected: true },
                { index: 1, label: statisticsAndQuestionsLabels.questions, selected: false },
            ],
        }
    },

    watch: {
        // eslint-disable-next-line no-unused-vars
        selectedContest(newContest, oldContest){
            if(newContest){
                this.initCycles();
            }
        }
    },

    computed: {
        isMobile() {
            return BrowserUtils.isMobile();
        },

        contestFeedData() {
            if (this.selectedCycleOption) {
                return {
                    "contestId": this.selectedContest?.contestId,
                    "groupId": this.selectedContest?.groupId,
                    "cycleStartDate": this.selectedCycleOption?.value?.startDate,
                };
            } else {
                return null;
            }
        },
    },

    mounted() {
        if(this.selectedContest){
            this.initCycles();
        }
    },

    methods: {
        viewStatisticsAndQuestions(name) {
            this.statisticsAndQuestionsActiveIndex = statisticsAndQuestionsLabels.statistics === name ? 0 : 1;
        },

        initCycles() {
            this.cycleOptions = getContestCycles(this.selectedContest?.startDate);

            this.selectedCycleOption = this.cycleOptions[this.cycleOptions.length - 1];
        },
    }
};
</script>

<style>
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    background: #32364e !important;
    color: white !important;
}
</style>

<style scoped>
*:not(.pi),
::v-deep(.p-component) {
    font-family: "Trebuchet MS", "Verdana";
}

.p-md-5,
.p-md-7 {
    padding-bottom: 0 !important;
}

.tab-div-small-margin {
    margin: 0px 16px;
}

.pill-menu-bar.sub-header {
    margin-bottom: 1rem;
}

.content-cycle-container {
    display: flex;
    justify-content: center;
    gap: 4px;
    align-items: center;
    margin-bottom: 1rem;
}

.main-menu-bar {
    margin: 1rem 1rem 0 1rem;
}

.container {
    display: flex;
    flex-wrap: wrap;
}

::v-deep(.scroll-to-top-button) {
    right: 18vw;
}

::v-deep(.p-dropdown) {
    border: 2px solid #32364E;
    border-radius: 50px;
    padding: 2px;
    max-width: calc(100% - 50px);
}

::v-deep(.p-dropdown:hover, .p-dropdown:active, .p-dropdown:focus, .p-dropdown.p-focus, .p-inputwrapper-focus) {
    border-color: #32364e;
}

::v-deep(.p-dropdown-label) {
    color: #32364e;
    font-size: 1rem;
    padding: 2px 0px 2px 4px;
}

::v-deep(.p-dropdown-trigger) {
    width: 1.5rem;
}

::v-deep(.p-dropdown-trigger-icon) {
    color: #32364e;
    font-size: 0.9rem;
}

.p-dropdown:not(.p-disabled).p-focus,
::v-deep(.p-dropdown:not(.p-disabled):focus) {
    outline: none !important;
    box-shadow: none !important;
    border-color: unset;
}

@media (max-width: 760px) {

    .content-cycle-container {
        margin: 8px;
    }

    .main-menu-bar {
        margin: 1rem 1rem 1rem 1rem;
    }

    .card {
        border-radius: 0;
        margin-bottom: 2px;
        padding: 0 16px;
    }

    ::v-deep(.p-tabmenu .p-tabmenu-nav .p-tabmenuitem button) {
        background: #ffffff;
        border: solid #ffffff;
        border-width: 0 0 2px 0;
        padding: 6px;
    }

    ::v-deep(.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight button) {
        background: #ffffff;
        color: #33CC99;
        border: solid #33CC99;
        border-width: 0 0 2px 0;
        padding: 6px;
    }

    ::v-deep(.p-tabmenu-nav) {
        text-align: center;
        border-width: 0;
        display: flex;
        justify-content: space-around;
    }

    ::v-deep(.p-tabmenuitem) {
        padding-top: 0 !important;
    }
}
</style>