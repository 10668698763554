import APIService from '@/common/APIService';

class PuzzleServiceImpl {

    getPuzzles(contestId,limit,offset,publishedOnly) {
        return APIService.get(`/puzzles/contest/${contestId}?limit=${limit}&offset=${offset}&publishedOnly=${publishedOnly}`);
    }

    getContestAnswers(contestId, limit,offset,query) {
        return APIService.get(`/puzzles/contest/${contestId}/answers?limit=${limit}&offset=${offset}&query=${query}`, {});
    }

    getPuzzleAnswers(contestId, puzzleId) {
        return APIService.get(`/puzzles/contest/${contestId}/puzzle/${puzzleId}/answers`, {});
    }

    getPuzzleAnswerDetails(contestId, puzzleId, answerId) {
        return APIService.get(`/puzzles/contest/${contestId}/puzzle/${puzzleId}/answer/${answerId}/details`);
    }

    getPuzzleAnswerHints(contestId, puzzleId, answerId,limit,offset) {
        return APIService.get(`/puzzles/contest/${contestId}/puzzle/${puzzleId}/answer/${answerId}/hints?limit=${limit}&offset=${offset}`, {});
    }

    getContestAnswerHints(contestId, answerId, limit, offset) {
        return APIService.get(`/puzzles/contest/${contestId}/answer/${answerId}/hints?limit=${limit}&offset=${offset}`, {});
    }

    getActivePuzzleGameForCurrentUser(contestId, startDate, endDate) {
        return APIService.get(`/puzzles/puzzle/${contestId}/active/game?startDate=${startDate}&endDate=${endDate}`, {});
    }

    getPuzzleGameForCurrentUser(puzzleId, startDate, endDate) {
        return APIService.get(`/puzzles/puzzle/${puzzleId}/game?startDate=${startDate}&endDate=${endDate}`, {});
    }

    usePuzzleHintForCurrentUser(puzzleId, hintId) {
        return APIService.post(`/puzzles/puzzle/${puzzleId}/hint/${hintId}`, {});
    }

    showPuzzleAnswerForCurrentUser(puzzleId, answerId) {
        return APIService.post(`/puzzles/puzzle/${puzzleId}/answer/show/${answerId}`, {});
    }

    markPuzzleAnswerFoundForCurrentUser(puzzleId, answerId, found) {
        return APIService.post(`/puzzles/puzzle/${puzzleId}/answer/found/${answerId}?found=${found}`, {});
    }

    createPuzzle(contestId, name, description, startDate, endDate, rewardAmount, currencyId) {
        const createPuzzleReq = {
            name: name,
            description: description,
            startDate: startDate,
            endDate: endDate,
            rewardAmount: rewardAmount,
            currencyId: currencyId
        };
        return APIService.post(`/puzzles/contest/${contestId}/puzzle`, createPuzzleReq);
    }

    createPuzzleAnswer(contestId, puzzleId, answer, activeStartDate, activeEndDate, rewardAmount, currencyId) {
        const createPuzzleAnswerReq = {
            answer: answer,
            activeStartDate: activeStartDate,
            activeEndDate: activeEndDate,
            rewardAmount: rewardAmount,
            currencyId: currencyId
        };
        return APIService.post(`/puzzles/contest/${contestId}/puzzle/${puzzleId}`, createPuzzleAnswerReq);
    }

    updatePuzzleAnswer(updatedAnswer) {
        return APIService.put(`/puzzles/contest/${updatedAnswer.answer.contestId}/puzzle/${updatedAnswer.puzzleId}/answer/${updatedAnswer.answerId}`, updatedAnswer);
    }

    deletePuzzleAnswer(contestId, puzzleId, answerId) {
        return APIService.delete(`/puzzles/contest/${contestId}/puzzle/${puzzleId}/answer/${answerId}`, {});
    }

    createPuzzleAnswerHint(contestId, puzzleId, answerId, hint) {
        const createPuzzleAnswerHintReq = {
            hint: hint
        };
        return APIService.post(`/puzzles/contest/${contestId}/puzzle/${puzzleId}/answer/${answerId}/hint`, createPuzzleAnswerHintReq);
    }

    updatePuzzleAnswerOrdering(contestId, puzzleId, answerIds) {
        return APIService.put(`/puzzles/contest/${contestId}/puzzle/${puzzleId}/answer/ordering`, {answerIds:answerIds});
    }

    updatePuzzleAnswerHintOrdering(contestId, puzzleId, answerId, hintIds) {
        return APIService.put(`/puzzles/contest/${contestId}/puzzle/${puzzleId}/answer/${answerId}/hints/ordering`, {hintIds:hintIds});
    }

    deletePuzzleAnswerHint(contestId, puzzleId, answerId, hintId) {
        return APIService.delete(`/puzzles/contest/${contestId}/puzzle/${puzzleId}/answer/${answerId}/hint/${hintId}`, {});
    }

    updatePuzzle(contestId, puzzleId, name, description, startDate, endDate, rewardAmount, currencyId) {
        const createPuzzleReq = {
            name: name,
            description: description,
            startDate: startDate,
            endDate: endDate,
            rewardAmount: rewardAmount,
            currencyId: currencyId
        }
        return APIService.put(`/puzzles/contest/${contestId}/puzzle/${puzzleId}`, createPuzzleReq);
    }
    
    publishPuzzle(contestId, puzzleId, publishDate) {
        const publishReq = {
            publishDate: publishDate
        };
        return APIService.put(`/puzzles/contest/${contestId}/puzzle/${puzzleId}/publish`, publishReq);
    }

}

const puzzleService = new PuzzleServiceImpl();

export default puzzleService;