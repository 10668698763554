<template>
    <div>
        <div class="card" v-for="puzzle in puzzles" :key="puzzle.puzzleId">
            
            <h6>Name: {{ puzzle.name }}</h6>
            <h6>Description: {{ puzzle?.description }}</h6>
            <h6>Published Date: {{ puzzle?.publishedDate }}</h6>
            <h6>Start Date: {{ puzzle.startDate }}</h6>
            <h6>End Date: {{ puzzle.endDate }}</h6>
            <h6>Reward Amount: {{ puzzle?.rewardAmount }}</h6>
            <h6>Reward Currency: {{ getCurrencyText(puzzle.currencyId) }}</h6>

            <Button label="Edit" @click="editPuzzle(puzzle)"/>
            <Button v-if="!puzzle?.publishedDate" label="Publish" @click="publishPuzzle(puzzle)" style="margin-left: 16px;"/>
        </div>
    </div>
</template>

<script>
import { format } from 'date-fns';
import EventBus from '../../../../event-bus';
import PuzzleService from '../../../../service/PuzzleService';
import { getCurrencyText } from '../..';


export default {
    name: "PuzzleList",
    props: {
        selectedContest: {
            type: Object,
            required: false
        },
    },
    data() {
        return {
            getCurrencyText,
            
            puzzles:null,
        };
    },
    components: {
    },
    mounted() {
        this.init();

        EventBus.off('refresh-puzzles-list');
        EventBus.on('refresh-puzzles-list', this.fetchPuzzles);
    },
    methods: {
        init(){
            this.fetchPuzzles();
        },

        fetchPuzzles(){
            PuzzleService.getPuzzles(this.selectedContest.contestId, 100, 0, false).then(resp => {
                if (resp.data.status == 'success') {
                    console.log("getPuzzles",resp);
                    this.puzzles = resp.data.puzzles.data;
                }
            });
        },

        editPuzzle(puzzle) {
            this.$store.commit('SET_SELECTED_CONTEST_PUZZLE', puzzle);
            this.$router.push('/contests/' + this.selectedContest?.contestId + '/manage/' + puzzle.puzzleId);
        },

        publishPuzzle(puzzle) {
            const publishDate = format(new Date(puzzle.startDate), "yyyyMMdd");
            PuzzleService.publishPuzzle(this.selectedContest.contestId, puzzle.puzzleId, publishDate).then(resp => {
                if (resp.data.status == 'success') {
                    console.log("publishPuzzle",resp);
                    this.fetchPuzzles();
                    this.$toast.add({ severity: 'success', summary: 'Published puzzle.', detail: '', life: 1000, group: 'center' });
                }
            });
        },
    }
};
</script>

<style scoped>

</style>