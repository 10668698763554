<template>
    <div v-if="selectedCompetition && isMobile" style="margin-bottom: 50px;">
        <div class="card">
            <TabMenu :model="statisticsAndAnnouncementsItems" :activeIndex="statisticsAndAnnouncementsActiveIndex"
                style="position:unset;">
                <template #item="{ item }">
                    <button @click="viewStatisticsAndAnnouncements(item.label)">
                        {{ item.label }}
                    </button>
                </template>
            </TabMenu>
        </div>
        <template v-if="selectedCycleOption">
            <div class="card" v-if="statisticsAndAnnouncementsActiveIndex == 0">
                <CompetitionStats miniVersion :isGroupOwnerAdminOrMod="isGroupOwnerAdminOrMod" :competition="selectedCompetition" :cycle="selectedCycleOption" />
            </div>
            <FeedActivityList v-else-if="( !selectedCompetition.privateContest || (isGroupOwnerAdminOrMod || selectedCompetition?.registered))" :selectedContestData="competitionFeedData" scrollStyle='width: 100%; height: calc(72vh)' >
                <div v-if="isGroupOwnerAdminOrMod" class="card status-editor-card">
                    <StatusEditor :selectedContest="selectedCompetition" :showNotesOnlyButton="false" :showPostMotionButton="false" 
                    :selectedGroup="selectedCompetition.hostingGroup" :selectedGroupSubFeed="{groupFeedId: selectedCompetition.groupFeedId}" :showFeedSourceDropdown="false"/>
                </div>
            </FeedActivityList>
        </template>
    </div>

    <div v-else-if="selectedCompetition">
        <div class="container">
            <div class="p-md-7">
                <CompetitionStats v-if="selectedCycleOption" :isGroupOwnerAdminOrMod="isGroupOwnerAdminOrMod" :competition="selectedCompetition" :cycle="selectedCycleOption" />
            </div>

            <div class="p-md-5">
                <template v-if="competitionFeedData && ( !selectedCompetition.privateContest || (isGroupOwnerAdminOrMod || selectedCompetition?.registered))">
                    <FeedActivityList :selectedContestData="competitionFeedData"
                        scrollStyle='width: 100%; height: calc(100vh - 110px)' >
                        <div class="card status-editor-card">
                            <StatusEditor :disablePost="!isGroupOwnerAdminOrMod && !selectedCompetition?.registered" :disableAsk="!isGroupOwnerAdminOrMod && !selectedCompetition?.registered" :selectedContest="selectedCompetition" :showNotesOnlyButton="false" :showPostMotionButton="false" 
                            :selectedGroup="selectedCompetition.hostingGroup" :selectedGroupSubFeed="{groupFeedId: selectedCompetition.groupFeedId}" :showFeedSourceDropdown="false"/>
                            <span class="feed-header">Posts</span>
                        </div>
                    </FeedActivityList>
                </template>
            </div>
        </div>
    </div>

    <ConfirmationModal header="Registration Successful" :bodyText="confirmationText" ref="confirmationModal" />
</template>

<script>
import ConfirmationModal from '../../modal/ConfirmationModal.vue';
import CompetitionStats from './CompetitionStats.vue';
import FeedActivityList from '../../feed/FeedActivityList.vue';
import TabMenu from 'primevue/tabmenu';
import StatusEditor from '../../feed/StatusEditor.vue';

import BrowserUtils from '../../../utilities/BrowserUtils';
import FormattingUtils from '../../../utilities/FormattingUtils';
import { format } from "date-fns";
import UserUtils from '../../../utilities/UserUtils';
import GroupService from '../../../service/GroupService';
import { CURRENCY_TYPES } from '../../../common/constants';
 
const statisticsAndAnnouncementsLabels = {
    statistics: 'Statistics',
    announcements: 'Announcements'
}

export default {
    name: 'CompetitionDetails',
    props: {
        selectedCompetition: {
            type: Object,
            required: false
        },
    },
    components: {
        ConfirmationModal,
        CompetitionStats,
        FeedActivityList,
        TabMenu,
        StatusEditor
    },
    data() {
        return {
            competitionsLoading: true,
            confirmationText: null,

            formatter: new FormattingUtils(),

            competitionsMenuItems: [],

            statisticsAndAnnouncementsActiveIndex: 0,
            statisticsAndAnnouncementsItems: [
                { index: 0, label: statisticsAndAnnouncementsLabels.statistics, selected: true },
                { index: 1, label: statisticsAndAnnouncementsLabels.announcements, selected: false },
            ],

            isGroupOwnerAdminOrMod: false,
        }
    },

    computed: {
        isMobile() {
            return BrowserUtils.isMobile();
        },

        selectedCycleOption() {
            let startDateOfCompetitionCycle;
            let endDateOfCompetitionCycle;
            let cycleName;
        
            startDateOfCompetitionCycle = new Date(this.selectedCompetition?.startDate);
            endDateOfCompetitionCycle = new Date(this.selectedCompetition?.endDate);

            cycleName = format(startDateOfCompetitionCycle, "MMM d") + ' - ' + format(endDateOfCompetitionCycle, "MMM d yyyy");
            
            return {
                label: cycleName,
                value: { id: 1, name: cycleName, startDate: format(startDateOfCompetitionCycle, "yyyyMMdd"), endDate: format(endDateOfCompetitionCycle, "yyyyMMdd") },
            }
        },

        competitionFeedData() {
            const feed = this.selectedCycleOption ? {
                    "contestId": this.selectedCompetition?.contestId,
                    "groupId": this.selectedCompetition?.groupId,
            } : null;
            return feed;
        },
    },

    watch: {
        // eslint-disable-next-line no-unused-vars
        $route(newRoute, oldRoute){
            if (newRoute.query.justRegistered) {
                this.openNewContestConformationModal();
            }
        }
    },

    mounted() {
        this.fetchGroupMemberRoles();

        if (this.$route.query.justRegistered) {
            this.openNewContestConformationModal();
        }
        
    },

    methods: {
        fetchGroupMemberRoles() {
            GroupService.getGroupMemberRoles(this.selectedCompetition?.hostingGroup?.groupId, this.$store.state.users.user.userId).then(resp=>{
                if (resp.data.status == "success") {
                    const roles = resp.data.roles;
                    this.$store.commit('users/SET_GROUP_ROLES', [...roles]);
                    this.isGroupOwnerAdminOrMod = UserUtils.isUser(this.selectedCompetition.hostingGroup?.groupOwnerId) 
                        || UserUtils.isGroupAdmin(roles) || UserUtils.isGroupMod(roles);
                }
            });
        },

        viewStatisticsAndAnnouncements(name) {
            this.statisticsAndAnnouncementsActiveIndex = statisticsAndAnnouncementsLabels.statistics === name ? 0 : 1;
        },

        openNewContestConformationModal() {
            this.populateConfirmationText();
            this.$refs.confirmationModal.open();
        },

        populateConfirmationText() {
            let baseText = `Welcome to the ${this.selectedCompetition.name}.\nYou have been successfully registered as a contestant.
            \nYou can check here to see your current rankings and review any posts/annoucements made by the host investment group ${this.selectedCompetition.hostingGroup.name}.\n`;

            if (this.selectedCompetition.categoryId === 1) {
                let acctName = '';
                const endString = ' - Comp Acct';
                if (this.selectedCompetition.name.length < 39) {
                    acctName = this.selectedCompetition.name + endString;
                } else {
                    acctName = this.selectedCompetition.name.substring(0, 39) + endString;
                }
                baseText += `\nA competition investment account has automatically been created for you.\nYou can see your investment account by clicking on the Portfolios menu button and then selecting the account called ${acctName}.\n`;
            }

            if (this.selectedCompetition.preFund) {
                baseText += `\nThe account has also been pre-funded with ${this.formatter.format(this.selectedCompetition.minStartingCash, '$0.00')} in ${CURRENCY_TYPES[this.selectedCompetition.currencyId]['SYMBOL']} Playdough.\n`;
            }
            
            this.confirmationText = baseText += `\nYou will only be able to make trades once the market opens at the start of the competition.`;
        }
    }
};
</script>

<style>
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    background: #32364e !important;
    color: white !important;
}
</style>

<style scoped>
*:not(.pi),
::v-deep(.p-component) {
    font-family: "Trebuchet MS", "Verdana";
}

::v-deep(.status-form-top-row) {
    padding: 0;
}

.p-md-5,
.p-md-7 {
    padding-bottom: 0 !important;
}

.tab-div-small-margin {
    margin: 0px 16px;
}

.pill-menu-bar.sub-header {
    margin-bottom: 1rem;
}

.content-cycle-container {
    display: flex;
    justify-content: center;
    gap: 4px;
    align-items: center;
    margin-bottom: 1rem;
}

.main-menu-bar {
    margin: 1rem 1rem 0 1rem;
}

.container {
    display: flex;
    flex-wrap: wrap;
}

.status-editor-card {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    padding: 12px;
}

.feed-header {
    border: solid #33CC99;
    color: #33CC99;
    border-width: 0 0 2px 0;
    padding: 8px;
    width: fit-content;
    align-self: center;
}

::v-deep(.scroll-to-top-button) {
    right: 18vw;
}

::v-deep(.p-dropdown) {
    border: 2px solid #32364E;
    border-radius: 50px;
    padding: 2px;
    max-width: calc(100% - 50px);
}

::v-deep(.p-dropdown:hover, .p-dropdown:active, .p-dropdown:focus, .p-dropdown.p-focus, .p-inputwrapper-focus) {
    border-color: #32364e;
}

::v-deep(.p-dropdown-label) {
    color: #32364e;
    font-size: 1rem;
    padding: 2px 0px 2px 4px;
}

::v-deep(.p-dropdown-trigger) {
    width: 1.5rem;
}

::v-deep(.p-dropdown-trigger-icon) {
    color: #32364e;
    font-size: 0.9rem;
}

.p-dropdown:not(.p-disabled).p-focus,
::v-deep(.p-dropdown:not(.p-disabled):focus) {
    outline: none !important;
    box-shadow: none !important;
    border-color: unset;
}

@media (max-width: 760px) {

    .content-cycle-container {
        margin: 8px;
    }

    .main-menu-bar {
        margin: 1rem 1rem 1rem 1rem;
    }

    .card {
        border-radius: 0;
        margin-bottom: 2px;
        padding: 0 16px;
    }

    ::v-deep(.p-tabmenu .p-tabmenu-nav .p-tabmenuitem button) {
        background: #ffffff;
        border: solid #ffffff;
        border-width: 0 0 2px 0;
        padding: 6px;
    }

    ::v-deep(.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight button) {
        background: #ffffff;
        color: #33CC99;
        border: solid #33CC99;
        border-width: 0 0 2px 0;
        padding: 6px;
    }

    ::v-deep(.p-tabmenu-nav) {
        text-align: center;
        border-width: 0;
        display: flex;
        justify-content: space-around;
    }

    ::v-deep(.p-tabmenuitem) {
        padding-top: 0 !important;
    }

    .status-editor-card {
        padding: 8px;
    }
}
</style>