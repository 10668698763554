<template>
    <Dialog :header="headerText" v-model:visible="display" modal :draggable="false" closable class="create-puzzle-modal">
        <h6>Name:</h6>
        <InputText type="text" v-model='name' :maxlength="100" placeholder="Enter Name" />

        <h6>Description (optional):</h6>
        <Textarea rows="5" cols="30" v-model='description' placeholder="Enter Description" />

        <h6>Start And End Dates:</h6>
        <Calendar dateFormat="yy/mm/dd" showIcon v-model="dateRange" :minDate="minStartDate" :maxDate="maxEndDate"
            selectionMode="range" :manualInput="false" placeholder="Select dates" />

        <h6>Reward Amount (optional):</h6>
        <InputNumber v-model='rewardAmount' placeholder="Enter Reward Amount" />

        <template #footer>
            <Button :label="footerButtonText" class="green-button__secondary" @click="onCreateClick" />
        </template>
    </Dialog>
</template>

<script>
import PuzzleService from '../../../../service/PuzzleService';
import EventBus from '../../../../event-bus';

import Calendar from 'primevue/calendar';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Textarea from 'primevue/textarea';
import { nextMonday, nextSunday } from 'date-fns';

const dialogTexts = {
    create: {
        header: "Add Puzzle",
        footerButton: "Create"
    },
    edit: {
        header: "Edit Puzzle",
        footerButton: "Save"
    },
}

export default {
    name: 'CreateEditPuzzleModal',
    props: {
        selectedContest: {
            type: Object,
            required: false
        },
    },
    components: {
    Dialog,
    InputText,
    Calendar,
    InputNumber,
    Textarea
},

    data() {
        return {
            display: false,
            puzzleToEdit: null,

            dateRange: null,
            minStartDate: null,
            maxEndDate: null,
            rewardAmount: null,
            name: '',
            description: '',
        }
    },

    computed: {
        headerText() {
            return this.puzzleToEdit ? dialogTexts.edit.header : dialogTexts.create.header;
        },

        footerButtonText() {
            return this.puzzleToEdit ? dialogTexts.edit.footerButton : dialogTexts.create.footerButton;
        }
    },

    methods: {
        open(puzzleToEdit) {
            this.display = true;
            this.puzzleToEdit = puzzleToEdit;

            this.minStartDate = new Date(this.selectedContest.startDate);
            this.maxEndDate = this.selectedContest.endDate ? new Date(this.selectedContest.endDate) : null;

            if (this.puzzleToEdit) {
                this.dateRange = [new Date(puzzleToEdit.startDate), new Date(puzzleToEdit.endDate)];
                this.rewardAmount = puzzleToEdit.rewardAmount;
                this.name = puzzleToEdit.name;
                this.description = puzzleToEdit.description;
            } else {
                const today = new Date();
                const nextMondayDay = nextMonday(today);
                this.dateRange = [nextMondayDay, nextSunday(nextMondayDay)];
                this.rewardAmount = null;
                this.name = '';
                this.description = '';
            }
        },

        onCreateClick() {
            if (this.name?.length == 0) {
                this.$toast.add({ severity: 'info', summary: 'Enter Name.', detail: '', life: 3000, group: 'tc' });
                return;
            }
            if (!this.dateRange[0] || !this.dateRange[1]) {
                this.$toast.add({ severity: 'info', summary: 'Select Start And End Dates.', detail: '', life: 3000, group: 'tc' });
                return;
            }

            if (this.puzzleToEdit) {
                PuzzleService.updatePuzzle(this.selectedContest.contestId, this.puzzleToEdit.puzzleId, this.name, this.description, this.dateRange[0], this.dateRange[1],
                    this.rewardAmount, this.selectedContest.currencyId).then(resp => {

                        if (resp.data.status == 'success') {
                            this.display = false;
                            this.$store.commit('SET_SELECTED_CONTEST_PUZZLE', resp.data.puzzle);
                            this.$toast.add({ severity: 'success', summary: 'Successfully updated puzzle!', detail: '', life: 3000, group: 'tc' });
                        } else {
                            this.$toast.add({ severity: 'error', summary: 'Sorry an unexpected error occurred. Please try again or contact support@invrs.com if the problem persists.', life: 3000, group: 'center' });
                        }
                    });
            } else {
                PuzzleService.createPuzzle(this.selectedContest.contestId, this.name, this.description, this.dateRange[0], this.dateRange[1],
                    this.rewardAmount, this.selectedContest.currencyId).then(resp => {

                        if (resp.data.status == 'success') {
                            this.display = false;
                            EventBus.emit('refresh-puzzles-list');
                            this.$toast.add({ severity: 'success', summary: 'Successfully created puzzle!', detail: '', life: 3000, group: 'tc' });
                        } else {
                            this.$toast.add({ severity: 'error', summary: 'Sorry an unexpected error occurred. Please try again or contact support@invrs.com if the problem persists.', life: 3000, group: 'center' });
                        }
                    });
            }
        }
    },
}
</script>

<style>
.create-puzzle-modal {
    width: 400px;
}
</style>
<style scoped>
.create-puzzle-modal .p-dialog-content * {
    width: 100%;
}
</style>