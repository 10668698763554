<template>
    <Dialog header="Add Clue" v-model:visible="display" modal :draggable="false" closable
        class="create-puzzle-answer-hint-modal">

        <div v-if="existingHints && existingHints?.length > 0">
            <h6>Choose Existing Clue:</h6>
            
            <div class="hints-scrollable-container">
                <div class="existing-hint-item" v-for="existingHint in existingHints" :key="existingHint.hintId">
                    <span style="flex-grow: 1; word-wrap: anywhere;">{{ existingHint.text }}</span>
                    <Button label="Add" class="green-button__secondary" style="min-width: fit-content;" @click="addExistingHint(existingHint)"/>
                </div>
            </div>
        </div>

        <h6>Create New Clue:</h6>

        <Textarea rows="5" cols="30" v-model='hint' :maxlength="500" placeholder="Enter Clue" />

        <template #footer>
            <Button label="Create" class="green-button__secondary" @click="onCreateClick" />
        </template>
    </Dialog>
</template>

<script>
import PuzzleService from '../../../../service/PuzzleService';

import Dialog from 'primevue/dialog';
import Textarea from 'primevue/textarea';
import EventBus from '../../../../event-bus';

export default {
    name: 'CreateEditPuzzleAnswerHintModal',
    props: {
        selectedContest: {
            type: Object,
            required: false
        },
        selectedPuzzle: {
            type: Object,
            required: false
        },
    },
    components: {
    Dialog,
    Textarea,
},

    data() {
        return {
            display: false,

            answer: null,
            hint: '',

            existingHints: []
        }
    },

    methods: {
        open(answer) {
            this.display = true;

            this.answer = answer;

            this.hint = '';

            PuzzleService.getContestAnswerHints(answer.answer.contestId, answer.answerId, 50, 0).then(resp=>{

                if (resp.data.status == 'success') {
                    this.existingHints = resp.data?.hints?.data;
                    
                    console.log("getContestAnswerHints", resp, this.existingHints);
                }
            });
        },

        addExistingHint(existingHint) {
            this.display = false;

            PuzzleService.createPuzzleAnswerHint(this.selectedContest.contestId, this.selectedPuzzle.puzzleId,
                this.answer.answerId, existingHint).then(resp => {

                    if (resp.data.status == 'success') {
                        this.display = false;
                        EventBus.emit('refresh-puzzle-answer-hints', this.answer.answerId);
                        this.$toast.add({ severity: 'success', summary: 'Successfully added clue', detail: '', life: 3000, group: 'tc' });
                    } else {
                        this.$toast.add({ severity: 'error', summary: 'Sorry an unexpected error occurred. Please try again or contact support@invrs.com if the problem persists.', life: 3000, group: 'center' });
                    }
                });
        },

        onCreateClick() {
            if (this.hint?.length == 0) {
                this.$toast.add({ severity: 'info', summary: 'Enter Clue.', detail: '', life: 3000, group: 'tc' });
                return;
            }

            const hint = {
                text:this.hint,
                answerId:this.answer.answerId
            };
            PuzzleService.createPuzzleAnswerHint(this.selectedContest.contestId, this.selectedPuzzle.puzzleId,
                this.answer.answerId, hint).then(resp => {

                    if (resp.data.status == 'success') {
                        this.display = false;
                        EventBus.emit('refresh-puzzle-answer-hints', this.answer.answerId);
                        this.$toast.add({ severity: 'success', summary: 'Successfully added clue', detail: '', life: 3000, group: 'tc' });
                    } else {
                        this.$toast.add({ severity: 'error', summary: 'Sorry an unexpected error occurred. Please try again or contact support@invrs.com if the problem persists.', life: 3000, group: 'center' });
                    }
                });
        }
    },
}
</script>

<style>
.create-puzzle-answer-hint-modal {
    width: 400px;
}
</style>
<style scoped>
.create-puzzle-answer-hint-modal .p-dialog-content > * {
    width: 100%;
}

::v-deep(.p-autocomplete-input) {
    width: 100% !important;
}

.existing-hint-item {
    display: flex; 
    gap: 4px; 
    align-items: center; 
    padding-bottom: 8px; 
    margin-bottom: 8px; 
    border-bottom: 1px solid black;
}

.hints-scrollable-container {
    max-height: 100px;
    overflow-y: scroll;
    overflow-x: hidden;
    white-space: break-spaces;
    scrollbar-color: #bfbfbf #f2f2f2;
    scrollbar-width: thin;
}

.hints-scrollable-container {
    padding-bottom: 8px;
    margin-bottom: 4px;
}

.hints-scrollable-container::-webkit-scrollbar {
    width: 10px;
}

.hints-scrollable-container::-webkit-scrollbar-track {
    background-color: #f2f2f2;
    opacity: 1;
    transition: background-color 0.2s;
}

.hints-scrollable-container::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background: #bfbfbf;
    /* color of the scroll thumb */
    border-radius: 10px;
    /* roundness of the scroll thumb */
}
</style>