<template>
    <Dialog :header="headerText" v-model:visible="display" modal :draggable="false" closable
        class="create-puzzle-answer-modal">

        <h6>Answer:</h6>
        <div style="display: flex; flex-direction: row; gap: 16px; align-items: center;">
            <div style="display: flex; flex-direction: row; gap: 4px; align-items: center;  padding-bottom: 8px;">
                <RadioButton v-model="useExistingAnswer" inputId="useExistingAnswer1" name="useExistingAnswer" 
                    :value="true" @change="onUseExistingAnswerChange" />
                <label for="useExistingAnswer1">Use Existing</label>
            </div>
            <div style="display: flex; flex-direction: row; gap: 4px; align-items: center; padding-bottom: 8px;">
                <RadioButton v-model="useExistingAnswer" inputId="useExistingAnswer2" name="useExistingAnswer" 
                    :value="false" @change="onUseExistingAnswerChange" />
                <label for="useExistingAnswer2">Create New</label>
            </div>
        </div>
        <AutoComplete v-if="useExistingAnswer" class="answer-auto-complete" v-model="answerText" :suggestions="filteredAnswers"
            @complete="searchAnswers($event)" @item-select="answerSelected($event)" placeholder="Search Answer" field="text"
            @keydown="onAnswerInputKeydown" :forceSelection="useExistingAnswer">
            <template #item="slotProps">{{ slotProps.item.text }}</template>
        </AutoComplete>
        <InputText v-else class="answer-auto-complete" v-model="answerText" placeholder="Enter Answer" :maxlength="50"/>

        <h6>Details (optional):</h6>
        <Textarea rows="5" cols="30" v-model='answerDetails' placeholder="Enter Details" />

        <h6>Start And End Dates:</h6>
        <Calendar dateFormat="yy/mm/dd" showIcon v-model="dateRange" :minDate="minStartDate" :maxDate="maxEndDate"
            selectionMode="range" :manualInput="false" placeholder="Select dates" />

        <h6>Reward Amount (optional):</h6>
        <InputNumber v-model='rewardAmount' placeholder="Enter Reward Amount" />

        <template #footer>
            <Button :label="footerButtonText" class="green-button__secondary" @click="onCreateClick" />
        </template>
    </Dialog>
</template>

<script>
import PuzzleService from '../../../../service/PuzzleService';

import Calendar from 'primevue/calendar';
import Dialog from 'primevue/dialog';
import InputNumber from 'primevue/inputnumber';
import AutoComplete from 'primevue/autocomplete';
import Textarea from 'primevue/textarea';
import EventBus from '../../../../event-bus';
import { add } from 'date-fns';
import RadioButton from 'primevue/radiobutton';

const dialogTexts = {
    createAnswer: {
        header: "Add Answer",
        footerButton: "Create"
    },
    editAnswer: {
        header: "Edit Answer",
        footerButton: "Save"
    },
}

export default {
    name: 'CreateEditPuzzleAnswerModal',
    props: {
        selectedContest: {
            type: Object,
            required: false
        },
        selectedPuzzle: {
            type: Object,
            required: false
        },
    },
    components: {
    Dialog,
    Calendar,
    InputNumber,
    AutoComplete,
    Textarea,
    RadioButton
},

    data() {
        return {
            display: false,

            useExistingAnswer: true,
            existingAnswer: null,

            answerToEdit: null,

            dateRange: null,
            minStartDate: null,
            maxEndDate: null,
            rewardAmount: null,
            answerText: '',
            answerDetails: '',

            filteredAnswers: [],
        }
    },

    computed: {
        headerText() {
            return this.answerToEdit ? dialogTexts.editAnswer.header : dialogTexts.createAnswer.header;
        },
        
        footerButtonText() {
            return this.answerToEdit ? dialogTexts.editAnswer.footerButton : dialogTexts.createAnswer.footerButton;
        }
    },

    methods: {
        open(answerToEdit, lastAnswer) {
            this.display = true;
            this.answerToEdit = answerToEdit;
            console.log("answerToEdit", answerToEdit);

            this.minStartDate = new Date(this.selectedPuzzle.startDate);
            this.maxEndDate = this.selectedPuzzle.endDate ? new Date(this.selectedPuzzle.endDate) : null;

            if (this.answerToEdit) {
                this.dateRange = [new Date(answerToEdit.activeStartDate), new Date(answerToEdit.activeEndDate)];
                this.rewardAmount = answerToEdit.rewardAmount;
                this.answerText = answerToEdit.answer.text;
                this.answerDetails = answerToEdit.answer.details; 
            } else {
                if(lastAnswer) {
                    const d1 = new Date(lastAnswer?.activeEndDate);
                    const d2 = add(d1, { days: 1 });
                    this.dateRange = [d1, d2];
                } else {
                    const d2 = add(this.minStartDate, { days: 1 });
                    this.dateRange = [this.minStartDate, d2];
                }

                this.rewardAmount = null;
                this.answerText = '';
                this.answerDetails = ''; 
            }
        },

        onAnswerInputKeydown(event) {
            console.log(event);
        },

        onUseExistingAnswerChange () {
            this.answerText = '';
            this.existingAnswer = null;
        },

        searchAnswers(event) {
            PuzzleService.getContestAnswers(this.selectedContest.contestId, 100, 0, event.query).then(resp => {
                console.debug("getContestAnswers", resp.data);
                this.filteredAnswers = resp.data.answers.data;
                console.log("getContestAnswers", this.filteredAnswers);
            }).catch(error => {
                this.filteredAnswers = [];
                console.debug('There was an error:', error.response)
            });
        },

        answerSelected(event) {
            console.log("answerSelected", event);
            this.existingAnswer = event.value;
            this.answerText = event.value.text;
            PuzzleService.getPuzzleAnswerDetails(this.selectedContest.contestId, this.selectedPuzzle.puzzleId, event.value?.answerId).then(resp=>{
                console.log("getPuzzleAnswerDetails", resp);
                this.answerDetails = resp?.data?.contestAnswer?.details;
            });
        },

        onCreateClick() {
            if (this.answerText?.length == 0) {
                this.$toast.add({ severity: 'info', summary: 'Enter Answer.', detail: '', life: 3000, group: 'tc' });
                return;
            }
            if (!this.dateRange[0] || !this.dateRange[1]) {
                this.$toast.add({ severity: 'info', summary: 'Select Start And End Dates.', detail: '', life: 3000, group: 'tc' });
                return;
            }

            this.display = false;

            if(this.answerToEdit) {
                this.answerToEdit["answer"]["text"] = this.answerText;
                this.answerToEdit["answer"]["details"] = this.answerDetails;
                this.answerToEdit["activeStartDate"] = this.dateRange[0];
                this.answerToEdit["activeEndDate"] = this.dateRange[1];
                this.answerToEdit["rewardAmount"] = this.rewardAmount;

                PuzzleService.updatePuzzleAnswer(this.answerToEdit).then(resp => {
                    if (resp.data.status == 'success') {
                        EventBus.emit('refresh-puzzles-answers-list');
                        this.$toast.add({ severity: 'success', summary: 'Successfully created puzzle', detail: '', life: 3000, group: 'tc' });
                    } else {
                        this.$toast.add({ severity: 'error', summary: 'Sorry an unexpected error occurred. Please try again or contact support@invrs.com if the problem persists.', life: 3000, group: 'center' });
                    }
                });
            } else {
                let answer;
                if(this.existingAnswer){
                    answer = this.existingAnswer;
                    answer["details"] = this.answerDetails;
                } else {
                    answer = {
                        contestId: this.selectedContest.contestId,
                        text: this.answerText,
                        details: this.answerDetails
                    }
                }
                
                PuzzleService.createPuzzleAnswer(this.selectedContest.contestId, this.selectedPuzzle.puzzleId,
                    answer, this.dateRange[0], this.dateRange[1], this.rewardAmount, this.selectedContest.currencyId).then(resp => {
                    if (resp.data.status == 'success') {
                        EventBus.emit('refresh-puzzles-answers-list');
                        this.$toast.add({ severity: 'success', summary: 'Successfully created puzzle', detail: '', life: 3000, group: 'tc' });
                    } else {
                        this.$toast.add({ severity: 'error', summary: 'Sorry an unexpected error occurred. Please try again or contact support@invrs.com if the problem persists.', life: 3000, group: 'center' });
                    }
                });
            }
        }
    },
}
</script>

<style>
.create-puzzle-answer-modal {
    width: 400px;
}
</style>
<style scoped>
.create-puzzle-answer-modal .p-dialog-content > * {
    width: 100%;
}
::v-deep(.p-autocomplete-input) {
    width: 100% !important;
}
</style>