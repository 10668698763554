<template>
    <div v-if="isMobile">
        <div class="card" style="display: flex; flex-direction: column; align-items: center;">
            <div>
                <span>Cycle: </span>
                <Dropdown style="min-width: fit-content;" :options="cycleOptions" optionLabel="label" v-model="selectedCycleOption" />
            </div>
            <TabMenu :model="statisticsAndQuestionsItems" :activeIndex="statisticsAndQuestionsActiveIndex"
                style="position:unset; width: 100%;">
                <template #item="{ item }">
                    <button @click="viewStatisticsAndQuestions(item.label)">
                        {{ item.label }}
                    </button>
                </template>
            </TabMenu>
        </div>
        <LoadingSpinner v-if="loadingPuzzle" />
        <h6 v-else-if="!puzzle" class="card">No puzzle found</h6>
        <template v-else>
            <PuzzleGameWordsList v-show="statisticsAndQuestionsActiveIndex == 0" ref="puzzleGameWordsList" :key="puzzleGameWordsListKey"
                    @refresh-puzzle-game-progress="fetchActivePuzzleGameForCurrentUser" />
            <div v-show="statisticsAndQuestionsActiveIndex == 1" style="margin: 0 4px 60px;">
                <PuzzleGameGrid ref="puzzleGameGrid" :key="puzzleGameGridKey"/>
            </div>
            <div v-if="statisticsAndQuestionsActiveIndex == 2" style="margin-bottom: 50px; padding: 8px 8px 0px 8px;" class="card">
                <ContestStats puzzleStats :contest="selectedContest" :cycle="selectedCycleOption" miniVersion/>
            </div>
        </template>
    </div>

    <div v-else class="puzzle-game tab-div">
        <div class="top-container card">
            <span style="flex-grow: 1;">Description: {{ puzzle?.description }}</span>
            <div style="display: flex; align-items: center; margin-left: 2px;">
                <span>Cycle: </span>
                <Dropdown :options="cycleOptions" optionLabel="label" v-model="selectedCycleOption" />
            </div>
            <img class="finsearch-icon" src="assets/images/finsearch.png"/>
        </div>
        <div v-if="puzzleStatsSelected" class="puzzle-game-container">
            <ContestStats puzzleStats :contest="selectedContest" :cycle="selectedCycleOption"/>
        </div>
        <div v-show="!puzzleStatsSelected">
            <LoadingSpinner v-if="loadingPuzzle" />
            <h6 v-else-if="!puzzle" class="card">No puzzle found</h6>
            <template v-else>
                <div class="puzzle-game-container">
                    <div class="puzzle-game-list-container">
                        <PuzzleGameWordsList ref="puzzleGameWordsList" :key="puzzleGameWordsListKey"
                            @refresh-puzzle-game-progress="fetchActivePuzzleGameForCurrentUser" />
                    </div>
                    <div class="puzzle-game-grid-container">
                        <PuzzleGameGrid ref="puzzleGameGrid" :key="puzzleGameGridKey"/>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import PuzzleService from '../../../service/PuzzleService';
import BrowserUtils from '../../../utilities/BrowserUtils';
import { getWeeklyContestCycles } from '../index';
import UserService from '../../../service/UserService';
import EventBus from '../../../event-bus';

import PuzzleGameGrid from './PuzzleGameGrid';
import PuzzleGameWordsList from './PuzzleGameWordsList.vue';
import TabMenu from 'primevue/tabmenu';
import ContestStats from '../components/ContestStats.vue';
import Dropdown from 'primevue/dropdown';
import LoadingSpinner from '../../common/LoadingSpinner.vue';

const statisticsAndQuestionsLabels = {
    hints: 'Clues',
    puzzle: 'Puzzle',
    statistics: 'Statistics'
}

export default {
    name: "PuzzleGame",
    props: {
        selectedContest: {
            type: Object,
            required: false
        },
    },
    components: {
    PuzzleGameGrid,
    PuzzleGameWordsList,
    TabMenu,
    ContestStats,
    Dropdown,
    LoadingSpinner
},
    data() {
        return {
            loadingPuzzle: false,

            activeAnswers: null,
            puzzle: null,
            puzzleProgress: null,
            activeAnswer: null,

            selectedItem: null,

            statisticsAndQuestionsActiveIndex: 0,
            statisticsAndQuestionsItems: [
                { index: 0, label: statisticsAndQuestionsLabels.hints, selected: true },
                { index: 1, label: statisticsAndQuestionsLabels.puzzle, selected: false },
                { index: 2, label: statisticsAndQuestionsLabels.statistics, selected: false },
            ],
            cycleOptions: [],
            selectedCycleOption: null,
            puzzleStatsSelected: false,

            puzzleGameWordsListKey:0,
            puzzleGameGridKey:0,
        };
    },
    computed: {
        isMobile() {
            return BrowserUtils.isMobile();
        }
    },
    watch: {
        selectedCycleOption() {
            this.fetchActivePuzzleGameForCurrentUser();
        }
    },
    mounted() {

        EventBus.off('puzzle-stats-selected');
        EventBus.on('puzzle-stats-selected', this.showStats);

        this.initCycles();
    },
    methods: {
        showStats(puzzleStatsSelected) {
            this.puzzleStatsSelected = puzzleStatsSelected;
        },
  
        initCycles() {
            this.cycleOptions = getWeeklyContestCycles(this.selectedContest?.startDate);

            this.selectedCycleOption = this.cycleOptions[this.cycleOptions.length - 1];
        },

        viewStatisticsAndQuestions(name) {
            switch (name) {
                case statisticsAndQuestionsLabels.hints:
                    this.statisticsAndQuestionsActiveIndex = 0;
                    break;
                case statisticsAndQuestionsLabels.puzzle:
                    this.statisticsAndQuestionsActiveIndex = 1;
                    break;
                case statisticsAndQuestionsLabels.statistics:
                    this.statisticsAndQuestionsActiveIndex = 2;
            }
        },

        fetchActivePuzzleGameForCurrentUser() {
            this.loadingPuzzle = true;

            this.puzzleGameWordsListKey +=1;
            this.puzzleGameGridKey +=1;
            const dates = [this.selectedCycleOption?.value?.startDate, this.selectedCycleOption?.value?.endDate];

            const historicalPuzzle = this.selectedCycleOption?.value?.historical;

            const onResponse = (resp, isActive) => {
                this.loadingPuzzle = false;

                if (resp.data.status == 'success') {
                    this.activeAnswers = resp.data.game?.activeAnswers;
                    this.activeAnswer = this.activeAnswers[0];
                    this.puzzle = resp.data.game?.puzzle;
                    this.puzzleProgress = resp.data.game?.puzzleProgress;

                    this.puzzleProgress.foundAnswers.forEach(element => {
                        let a = this.puzzle.answers.find(ans => ans.answerId==element.answerId);
                        if(a) {
                            a.answer.text = UserService.decrypt(a.answer.text);
                            a.answer.found = true;
                        }
                    });

                    this.puzzle["isActive"] = isActive;

                    this.$nextTick(() => {
                        this.$refs.puzzleGameWordsList?.startGame(this.puzzle, this.activeAnswers, this.puzzleProgress, this.activeAnswer);
                        if(this.activeAnswer) {
                            this.$refs.puzzleGameGrid?.initializeGrid(this.puzzle, this.activeAnswer, this.puzzleProgress);
                        }
                    });
                } else {
                    this.activeAnswers = null;
                    this.activeAnswer = null;
                    this.puzzle = null;
                    this.puzzleProgress = null;
                }
            };

            if(historicalPuzzle) {
                PuzzleService.getPuzzleGameForCurrentUser(this.selectedContest.contestId, dates[0], dates[1]).then(resp=>onResponse(resp, false));
            } else {
                PuzzleService.getActivePuzzleGameForCurrentUser(this.selectedContest.contestId, dates[0], dates[1]).then(resp=>onResponse(resp, true));
            }
        },
    },
};
</script>

<style scoped>
.card {
    margin-bottom: 16px;
}

.top-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.top-container .finsearch-icon {
    width: 130px;
    margin-left: 20px;
}

.top-container .p-dropdown {
    min-width: fit-content;
    margin-left: 2px;
}

.puzzle-game {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
}   

.puzzle-game-container {
    display: flex;
    flex-direction: row;
}   

.puzzle-game-container ::v-deep(.card .description-row .fintrivia-icon) {
    display: none;
}

.puzzle-game-list-container {
    flex-grow: 1;
    margin-right: 16px;
    margin-top: 2px;
}

.puzzle-game-grid-container {
    height: calc(100vh - 120px);
    width: calc(100vh - 120px);
    max-width: 500px;
    max-height: 500px;
}

@media (max-width: 760px) {
    .card {
        margin-bottom: 8px !important;
        padding: 0px;
        border-radius: 0;
    }

    .answer-items-container {
        padding: 0px 8px !important;
    }
}
</style>