<template>
    <Dialog header='Current Holdings' v-model:visible="display" :modal="true" closable :draggable="false" class='holdings-modal'>
        
    
        <LoadingSpinner :showLoading="loading" v-if="loading" />
       
        <div class="holdings-section" v-else>
            <AllocationChart :sectorLabels="sectorLabels" :sectorValues="sectorValues" :assetLabels="assetLabels" :assetValues="assetValues" :showInfoIcon="false" :holdingLabels="holdingLabels" :holdingValues="holdingValues" :viewingOwnPortfolio="false" v-if="holdings.length > 0 && assetValues.length > 0 && sectorValues.length > 0" />
            <HoldingsTable :holdings="holdings" competitionPage />
        </div>
   
    </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import AllocationChart from '../overview/AllocationChart.vue';
import HoldingsTable from '../overview/HoldingsTable.vue';
import LoadingSpinner from '../../common/LoadingSpinner.vue';

import PortfolioService from '../../../service/PortfolioService';

export default {
    name: 'HoldingsTableModal',
    components: {
    Dialog,
    HoldingsTable,
    LoadingSpinner,
    AllocationChart
},
    props: {
        competition: {
            required: true,
        }
    },

    computed: {
        loading() {
            return !this.holdingsLoaded && !this.allocationDataLoaded;
        }
    },

    data() {
        return {
            display: false,

            holdings: [],
            holdingsLoaded: false,

            sectorLabels: [],
            sectorValues: [],
            assetLabels: [],
            assetValues: [],
            holdingLabels: [],
            holdingValues: [],
            allocationDataLoaded: false
        }
    },

    methods: {

        open(data) {
            this.display = true;

            this.allocationDataLoaded = false;
            this.holdingsLoaded = false;

            this.holdings = [];
            this.sectorLabels = [];
            this.sectorValues = [];
            this.assetLabels = [];
            this.assetValues = [];
            this.holdingLabels = [];
            this.holdingValues = [];

            PortfolioService.getCompetitionPortfolioBreakdown(this.competition.contestId, data?.analyst?.userId).then((allocationData)=> {
                if (allocationData.data.status === 'success') {
                    this.getDataFromCompetitionPortfolioAllocation(allocationData.data);
                }

                this.allocationDataLoaded = true;
            })
            .catch((err) => {
                this.allocationDataLoadedaded = true;

                this.$toast.add({ severity: 'error', summary: err, life: 4000, group: 'center' });
                console.err(err);  
            });

            PortfolioService.getCompetitionPortfolioHoldings(this.competition.contestId, data?.analyst?.userId).then(resp => {
                if (resp.data.status == 'success') {
                    this.getDataFromCompetitionPortfolioHoldings(resp.data);
                }

                this.holdingsLoaded = true;
            })
            .catch((err) => {
                this.holdingsLoaded = true;

                this.$toast.add({ severity: 'error', summary: err, life: 4000, group: 'center' });
                console.err(err);
            })
        },

        getDataFromCompetitionPortfolioHoldings(assetPositionData) {
            const equityPositions = assetPositionData.holdings.equityPositions;
            const fundPositions = assetPositionData.holdings.fundPositions;
            const derivativePositions = assetPositionData.holdings.derivativePositions;
            const fixedIncomePositions = assetPositionData.holdings.fixedIncomePositions;
            const otherPositions = assetPositionData.holdings.otherAssetPositions;

            const publicHoldingsArray = [];

            if (equityPositions.length > 0) {
                equityPositions.forEach((holding) => {
                    publicHoldingsArray.push(holding);
                });
            }

            if (fundPositions.length > 0) {
                fundPositions.forEach((holding) => {
                    publicHoldingsArray.push(holding);
                });
            }

            if (derivativePositions.length > 0) {
                derivativePositions.forEach((holding) => {
                    publicHoldingsArray.push(holding);
                });
            }

            if (fixedIncomePositions.length > 0) {
                fixedIncomePositions.forEach((holding) => {
                    publicHoldingsArray.push(holding);
                });
            }

            if (otherPositions.length > 0) {
                otherPositions.forEach((holding) => {
                    publicHoldingsArray.push(holding);
                });
            }

            const orderedHoldingsArray = publicHoldingsArray.sort((a, b) => {
                return b.weight - a.weight;
            });

            this.holdings = orderedHoldingsArray;

            let cumulativeWeight = 0;

            this.holdings.forEach(holding => {
                const weight = holding.weight * 100;
                this.holdingValues.push(weight);
                cumulativeWeight += weight;
                
                const ticker = holding.otherAsset?.proxySymbol || holding.otherAsset?.symbol || holding.derivative?.symbol || holding.securityListing?.symbol || holding.fund?.symbol;
                this.holdingLabels.push(ticker);
            });

            this.holdingValues.push(100 - cumulativeWeight);
            this.holdingLabels.push('Other');
        },

        getDataFromCompetitionPortfolioAllocation(allocationData) {

            const sectorData = allocationData.sectorMapping.dataPoints.filter((data) => {
                return data.value > 0;
            });
            const assetData = allocationData.classMapping.dataPoints.filter((data) => {
                return data.value > 0;
            });

            const labelValueGenerator = (data, sectorOrAsset) => {
                let formattedCategory = '';
                const splitCategory = data.category.split(' ');
                const formattedWords = splitCategory.map((word) => {
                    return word.replace(word[0], word[0].toUpperCase());
                });

                if (formattedWords.length > 1) {
                    formattedCategory = formattedWords.join(' ');
                } else {
                    formattedCategory = formattedWords[0];
                }

                if (sectorOrAsset === 'sector') {
                    this.sectorLabels.push(formattedCategory);
                    this.sectorValues.push((data.value * 100).toFixed(2));
                } else if (sectorOrAsset === 'asset') {
                    this.assetLabels.push(formattedCategory);
                    this.assetValues.push((data.value * 100).toFixed(2));
                }
            };

            sectorData.forEach((data) => {
                labelValueGenerator(data, 'sector');
            });

            assetData.forEach((data) => {
                labelValueGenerator(data, 'asset');
            });

        },

        onClose() {
            this.display = false;
        },

    },

}
</script>

<style>
.holdings-modal {
    font-family: "Trebuchet MS", "Verdana";
    max-width: 1100px;
    height: fit-content !important;
    display: flex;
    overflow: hidden;
}

.holdings-modal .p-dialog-header {
    padding: 16px;
}

.holdings-modal .p-dialog-content {
    padding: 0 16px 16px;
    overflow-y: auto;
    text-align: center;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    height: fit-content;
    max-height: 1000px;
    flex-grow: 1;
}
.holdings-modal .p-dialog-content::-webkit-scrollbar {
        /* width */
        width: 10px;
    }
.holdings-modal .p-dialog-content::-webkit-scrollbar-track {
    background-color: #F2F2F2;
    opacity: 1;
    transition: background-color .2s;
} 
.holdings-modal .p-dialog-content::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background: #BFBFBF;    /* color of the scroll thumb */
    border-radius: 10px;       /* roundness of the scroll thumb */
}


@media (max-width: 760px) {
    .holdings-modal {
        left: 0 !important;
        max-height: 100%;
        height: 100%;
        top: 0 !important;
        transform: none;
        transition: none;
        width: 100vw !important;
    }

    .holdings-modal .p-dialog-header {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .holdings-modal .p-dialog-content {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .holdings-modal .p-dialog-footer {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}
</style>
<style scoped>
*:not(.pi),
::v-deep(.p-component) {
    font-family: "Trebuchet MS", "Verdana";
}

.p-dialog-header .title {
    font-weight: bold;
    color: #32364e;
    font-size: 24px;
}

.body-text {
    color: black;
    margin-bottom: 16px;
}

.buttons-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.p-button {
    border-radius: 16px;
    margin-bottom: 8px;
}

.p-button:last-of-type {
    margin-bottom: 0;
}

.holdings-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.holdings-section ::v-deep(.allocation-card) {
    width: 40%;
}

@media (max-width: 760px) {}
</style>