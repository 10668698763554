<template>
    <div :class="isMobile ? 'tab-div' : 'tab-div-small-margin'">
        <PillMenuBar v-if="navMenuItems && navMenuItems.length > 0" class="main-menu-bar"
            :navigationItems="navMenuItems" headerType="main" >
            <template #custom>
                <Button v-if="showGoBackButton()" label="Go Back" @click="goBack" class="go-back-button green-button__secondary"/>
                <Button v-if="!isMobile && isGroupOwnerAdminOrMod && isPuzzleContestSelected() 
                    && !puzzleStatsSelected && !isManagePathSelected()" label="Manage"
                    @click="goToManageContest" class="go-back-button green-button__secondary" />
                <Button v-if="!isMobile && isPuzzleContestSelected() && !puzzleStatsSelected && !isManagePathSelected() && !isManagePuzzlePathSelected()" label="Show Stats"
                    @click="showOrHideContestStats" class="go-back-button green-button__secondary" />
            </template>
        </PillMenuBar>

        <CompetitionsList v-if="showComps" :loading="competitionsLoading" :competitions="competitions" @select-comp="onCompSelected"
            @change-page="onChangePage" :limit="limit" :offset="offset"  :total="total"/>
        <CompetitionDetails v-else-if="showCompDetails" :selectedCompetition="selectedCompetition" />
        <PuzzleEdit v-else-if="isPuzzleContestSelected() && isManagePuzzlePathSelected()" :selectedContest="selectedContest" />
        <PuzzlesManage v-else-if="isPuzzleContestSelected() && isManagePathSelected()" :selectedContest="selectedContest" />
        <PuzzleGame v-else-if="isPuzzleContestSelected()" :selectedContest="selectedContest" />
        <ContestHome v-else-if="selectedContest" :selectedContest="selectedContest" />
    </div>

</template>

<script>
import PillMenuBar from '../components/menu/PillMenuBar.vue';
import ContestHome from '../components/contests/components/ContestHome.vue';
import CompetitionsList from '../components/contests/components/CompetitionsList';
import CompetitionDetails from '../components/contests/components/CompetitionDetails.vue';
import PuzzleGame from '../components/contests/puzzle/PuzzleGame.vue';
import PuzzlesManage from '../components/contests/puzzle/manage/PuzzlesManage.vue';
import PuzzleEdit from '../components/contests/puzzle/manage/PuzzleEdit.vue';

import GroupService from '../service/GroupService';
import BrowserUtils from '../utilities/BrowserUtils';
import router from '../router';
import { GROUP_CONTEST_CATEGORIES } from '../common/constants';
import EventBus from '../event-bus';
import UserUtils from '../utilities/UserUtils';

const competitionsItem = {
    name: "Competitions",
}

const routesMap = {
    contests: "/contests",
    competitions: "/contests/competitions",
}

export default {
    name: 'Contests',
    components: {
        PillMenuBar,
        ContestHome,
        CompetitionsList,
        CompetitionDetails,
        PuzzleGame,
        PuzzlesManage,
        PuzzleEdit
    },
    data() {
        return {
            routesMap, 

            contestsLoading: true,
            contests: [],
            selectedContest: null,

            competitionsLoading: true,
            competitions: [],
            selectedCompetition: null,
            
            navMenuItems: [],

            isGroupOwnerAdminOrMod: [],

            puzzleStatsSelected: false,
            limit: 5,
            offset: 0,
            total: 0,
        }
    },

    computed: {
        isMobile() {
            return BrowserUtils.isMobile();
        },

        showCompDetails() {
            return this.$route.path.startsWith(routesMap.competitions) && this.$route.params.contestId && this.selectedCompetition;
        },

        showComps() {
            return this.$route.path.startsWith(routesMap.competitions) && !this.$route.params.contestId ;
        },

        showContests() {
            return this.$route.path.startsWith(routesMap.contests) && !this.showComps && !this.showCompDetails;
        }
    },

    watch: {
        // eslint-disable-next-line no-unused-vars
        $route(newRoute, oldRoute){
            
            this.initSelectedItem('watch route');
            
        }
    },

    mounted() {
       
        EventBus.off('refresh-competitions');
        EventBus.on('refresh-competitions', this.fetchCompetitions);
    },

    activated() {
        console.log("activated")
       // if( this.showComps ) {
         this.init();
        //}

    },

    methods: {
        fetchGroupMemberRoles() {
            GroupService.getGroup(this.selectedContest?.groupId).then(resp=>{
                if (resp.data.status == "success") {
                    GroupService.getGroupMemberRoles(this.selectedContest?.groupId, this.$store.state.users.user.userId).then(resp2=>{
                        if (resp2.data.status == "success") {
                            const roles = resp2.data.roles;
                            //console.log("getGroupMemberRoles", this.selectedContest);
                            this.isGroupOwnerAdminOrMod = UserUtils.isUser(resp?.groupOwnerId) 
                                || UserUtils.isGroupAdmin(roles) || UserUtils.isGroupMod(roles);
                        }
                    });
                }
            });
        },

        showGoBackButton(){
            return this.isManagePathSelected() 
                || this.isCompetitionDetailsPathSelected() 
                || this.puzzleStatsSelected;
        },

        goBack() {
            if(this.puzzleStatsSelected) {
                this.showOrHideContestStats();
            } else if(this.isManagePathSelected()){
                router.push(routesMap.contests + '/' + this.selectedContest?.contestId);
            } else if(this.isCompetitionDetailsPathSelected()) {
                this.$router.push(routesMap.competitions);
                this.selectedCompetition = null;
            }
        },

        isPuzzleContestSelected() {
            return this.selectedContest?.categoryId === GROUP_CONTEST_CATEGORIES.puzzle;
        },

        isCompetitionDetailsPathSelected(){
            return (this.$route.path.startsWith(routesMap.competitions) && this.$route.params.contestId);
        },

        isManagePathSelected(){
            return this.$route.fullPath.endsWith("manage");
        },

        isManagePuzzlePathSelected(){
            return this.$route.fullPath.includes("manage") && this.$route.params.puzzleId;
        },

        goToManageContest() {
            if(this.isPuzzleContestSelected()){
                router.push(routesMap.contests + '/' + this.selectedContest?.contestId + '/manage');
            }
        },

        showOrHideContestStats() {
            if(this.isPuzzleContestSelected()){
                //router.push(routesMap.contests + '/' + this.selectedContest?.contestId + '/stats');
                this.puzzleStatsSelected = !this.puzzleStatsSelected;
                EventBus.emit('puzzle-stats-selected', this.puzzleStatsSelected);
            }
        },

        async init(){

            this.navMenuItems = [];
            this.navMenuItems.push({
                label: competitionsItem.name,
                action: () => this.selectNavMenuItem(competitionsItem, true),
            });

            this.fetchContests();
            
            if( this.showComps) {
                this.fetchCompetitions();
            }
            // else if (this.$route.path.startsWith(routesMap.competitions) && this.$route.params.contestId){
            //     this.initSelectedItem('show comp details');
            // }
        },

        onCompSelected(comp){
            //this.selectedCompetition = comp;
            this.$router.push(routesMap.competitions + '/' + comp.contestId);
        },

        initSelectedItem(ctx) {
            console.log("initSelectedItem called from context: "+ctx);
            this.puzzleStatsSelected = false;

            if(this.$route.path.startsWith(routesMap.competitions)  ) {
                if( this.$route.params.contestId ){
                    console.log("looking up contest id locally " + this.$route.params.contestId );
                    const item = this.competitions.find(item => item.contestId == this.$route.params.contestId);
                    if( item == null ){
                        console.log("comp not found locally, looking up ")
                        GroupService.getGroupContest(this.$route.params.contestId).then(resp => {
                            if( resp.data.status == 'success'){
                                this.selectedCompetition = resp.data.contest;
                            }
                            else {
                                console.log("error looking up group contest ", resp.data);
                            }
                        }).catch(err => {
                            console.log("error looking up group contest ", err);
                        });
                        
                    }
                    else {
                        this.selectedCompetition = item;
                       
                    }
                    
                   // this.selectNavMenuItem(item, true);
                  
                //    if( this.selectedCompetition ){
                //     router.push(routesMap.competitions + '/' + item.contestId);
                //    }
                }
                this.navMenuItems.forEach(menuItem => {
                    menuItem["selected"] =  (menuItem.label == competitionsItem.name);
                });
                
            } else if(this.$route.path.startsWith(routesMap.contests)){
                if(this.$route.params.puzzleId) {
                    // todo
                } else if(this.$route.params.contestId) {
                    const item = this.contests.find(item => item.contestId == this.$route.params.contestId);
                    if(item) {
                        this.selectNavMenuItem(item);
                    }
                } else if(this.contests[0]) {
                    this.selectNavMenuItem(this.contests[0]);
                }
            }
        },

        selectNavMenuItem(item, isCompetition) {
            //console.log("selectNavMenuItem", item, isCompetition);
            // if(item?.hostingGroup){
            //     this.selectedCompetition = item;
            //     router.push(routesMap.competitions + '/' + item.contestId);
            // } else 
            if(this.isManagePathSelected() && item?.contestId){
                this.selectedContest = item;
                router.push(routesMap.contests + '/' + item.contestId + '/manage');
            } else if(item?.contestId && !isCompetition){
                this.selectedContest = item;

                if(this.isPuzzleContestSelected()){   
                    this.fetchGroupMemberRoles();
                }
                
                router.push(routesMap.contests + '/' + item.contestId);
            } else {
                this.selectedContest = null;
                this.selectedCompetition = null;

                if (item?.name === competitionsItem.name) {
                    router.push(routesMap.competitions);
                }
            }

            this.navMenuItems.forEach(menuItem => {
                menuItem["selected"] = isCompetition ? menuItem.label == competitionsItem.name : menuItem.label == item.name
            });
            // console.log("this.selectedContest", this.selectedCompetition, this.navMenuItems);
        },

        async fetchContests() {
            this.contestsLoading = true;
            return GroupService.getSystemContests().then(resp => {
                this.contestsLoading = false;
                if (resp.data.status == "success") {

                    this.contests = resp.data.contests;

                   

                    this.contests.forEach(contest => {
                        this.navMenuItems.push({
                            label: contest.name,
                            action: () => this.selectNavMenuItem(contest)
                        });
                    });
                    
                    this.initSelectedItem("fetch contests");
                }
            });
        },

        onChangePage(pageState){
            console.log("got change page event with page state of ", pageState)
            this.offset = pageState.first;
            this.competitionsLoading = true;
            return GroupService.getGroupsHostedContests(this.limit, this.offset).then(resp => {
                this.competitionsLoading = false;
                if (resp.data.status == "success") {

                    this.competitions = resp.data.contests.data;
                    this.total = resp.data.contests.totalCount;
                    window.scrollTo(0, 0);
                   
                }
            });
        },

        async fetchCompetitions() {
            this.competitionsLoading = true;
            return GroupService.getGroupsHostedContests(this.limit, this.offset).then(resp => {
                this.competitionsLoading = false;
                if (resp.data.status == "success") {

                    this.competitions = resp.data.contests.data;
                    this.total = resp.data.contests.totalCount;
                    // console.log("getGroupsHostedContests", this.competitions);

                    this.initSelectedItem('fech competitions');
                }
            });
        },
    }
};
</script>

<style>
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    background: #32364e !important;
    color: white !important;
}
</style>

<style scoped>
*:not(.pi),
::v-deep(.p-component) {
    font-family: "Trebuchet MS", "Verdana";
}

.tab-div-small-margin {
    margin: 0px 16px;
}

.pill-menu-bar.sub-header {
    margin-bottom: 1rem;
}

.content-cycle-container {
    display: flex;
    justify-content: center;
    gap: 4px;
    align-items: center;
    margin-bottom: 1rem;
}

.main-menu-bar {
    margin: 1rem 1rem 0 1rem;
    padding: 0 !important;
}

.container {
    display: flex;
    flex-wrap: wrap;
}

::v-deep(.scroll-to-top-button) {
    right: 18vw;
}

::v-deep(.p-dropdown) {
    border: 2px solid #32364E;
    border-radius: 50px;
    padding: 2px;
    max-width: calc(100% - 50px);
}

::v-deep(.p-dropdown:hover, .p-dropdown:active, .p-dropdown:focus, .p-dropdown.p-focus, .p-inputwrapper-focus) {
    border-color: #32364e;
}

::v-deep(.p-dropdown-label) {
    color: #32364e;
    font-size: 1rem;
    padding: 2px 0px 2px 4px;
}

::v-deep(.p-dropdown-trigger) {
    width: 1.5rem;
}

::v-deep(.p-dropdown-trigger-icon) {
    color: #32364e;
    font-size: 0.9rem;
}

.go-back-button {
  padding: 8px 16px;
  border-style: solid;
  border-radius: 28px;
}

.p-dropdown:not(.p-disabled).p-focus,
::v-deep(.p-dropdown:not(.p-disabled):focus) {
    outline: none !important;
    box-shadow: none !important;
    border-color: unset;
}

@media (max-width: 760px) {

    .content-cycle-container {
        margin: 8px;
    }

    .main-menu-bar {
        margin: 0.5rem;
    }

    .card {
        border-radius: 0;
        margin-bottom: 2px;
        padding: 0 16px;
    }

    ::v-deep(.p-tabmenu .p-tabmenu-nav .p-tabmenuitem button) {
        background: #ffffff;
        border: solid #ffffff;
        border-width: 0 0 2px 0;
        padding: 6px;
    }

    ::v-deep(.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight button) {
        background: #ffffff;
        color: #33CC99;
        border: solid #33CC99;
        border-width: 0 0 2px 0;
        padding: 6px;
    }

    ::v-deep(.p-tabmenu-nav) {
        text-align: center;
        border-width: 0;
        display: flex;
        justify-content: space-around;
    }

    ::v-deep(.p-tabmenuitem) {
        padding-top: 0 !important;
    }
}
</style>