<template>
    <div class="container">
        <Button label="Create Puzzle" @click="openCreateEditPuzzleModal" style="margin-bottom: 16px;" />
        <PuzzleList :selectedContest="selectedContest"/>
    </div>
    <CreateEditPuzzleModal ref="createEditPuzzleModal" :selectedContest="selectedContest"/>
</template>

<script>
import CreateEditPuzzleModal from './CreateEditPuzzleModal.vue';
import PuzzleList from './PuzzleList.vue';


export default {
    name: "PuzzlesManage",
    props: {
        selectedContest: {
            type: Object,
            required: false
        },
    },
    data() {
        return {};
    },
    components: {
        CreateEditPuzzleModal,
        PuzzleList
    },
    methods: {
        openCreateEditPuzzleModal() {
            this.$refs.createEditPuzzleModal.open();
        },
    }
};
</script>

<style scoped>
.container {
    margin: 16px;
}
</style>