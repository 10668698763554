<template>
    <div :class="'answer-items-container card'">
        <div class="answer-item-container" v-for="answer in answers"
            :key="answer.answerId">
            <div>
                <h5 class="answer-title">{{ getAnswerTitleText(answer) }}</h5>
            </div>
            <div v-if="answer.answerId == activeAnswer?.answerId && activeAnswerHints">
                <div class="row" v-for="(activeAnswerHint, index) in activeAnswerHints" :key="activeAnswerHint.answerId"
                    style="min-height: 30px; margin-bottom: 4px;">

                    <span style="white-space: nowrap; margin-right: 2px;">Clue #{{ index + 1 }}: </span>
                    <span v-if="getHintText(answer, index)">
                        {{ getHintText(answer, index) }}
                    </span>
                    <Button v-else class="green-button__secondary" label="Show"
                        :disabled="isShowHintButtonDisabled(answer, index)" @click="onShowHintButtonClick(answer, index)" />
                </div>
            </div>
            <div class="row">
                <!-- <span v-if="isAnswerExpired(answer)">You didn't find the word</span> -->
                <span>Answer: </span>
                <InputText :key="answerInputKey" :class="isAnswerFound(answer) ? 'answer-found-input' : ''" :value="getAnswerText(answer)" :disabled="true" />
                <Button v-if="showAnswerButton(answer)" class="green-button__secondary" label="Show" style="margin-left: 2px;"
                    @click="onShowAnswerButtonClick(answer)" />
            </div>
        </div>
    </div>
</template>

<script>
import { isAfter } from 'date-fns';
import PuzzleService from '../../../service/PuzzleService';
import TimeUtils from '../../../utilities/TimeUtils';
import InputText from 'primevue/inputtext';
import EventBus from '../../../event-bus';
import UserService from '../../../service/UserService';


export default {
    name: "PuzzleGameWordsList",
    emits: ["refresh-puzzle-game-progress"],
    data() {
        return {
            puzzle: null,
            answers: null,
            activeAnswers: null,
            activeAnswer: null,
            activeAnswerHints: null,
            puzzleProgress: null,
            answerInputKey: 0,
        };
    },
    watch() {

    },
    mounted() {
        EventBus.off("puzzle-word-found");
        EventBus.on("puzzle-word-found", this.puzzleWordFound);
    },
    components: {
        InputText
    },
    methods: {

        puzzleWordFound(answerId) {
            if (this.activeAnswer?.answerId == answerId) {
                // this.activeAnswer.answer.text = UserService.decrypt(this.activeAnswer.answer.text);

                let a = this.answers.find(answer => answer.answerId == this.activeAnswer?.answerId);
                
                if(a && !a.answer?.found) {
                    a.answer.text = UserService.decrypt(a.answer.text);
                    a.answer.found = true;
                
                    PuzzleService.markPuzzleAnswerFoundForCurrentUser(this.puzzle.puzzleId, answerId, a.answer.text);
                
                    this.$confetti.start({
                        particlesPerFrame: 5,
                        windSpeedMax: 0
                    });

                    setTimeout(() => {
                        this.$confetti.stop();
                    }, 2500);

                    this.answerInputKey+=1;
                }
                // check whether puzzle is solved
            }
        },

        isActiveAnswer(answer) {
            return answer == this.activeAnswer?.answerId;
        },

        isAnswerExpired(answer) {
            return answer?.activeEndDate && isAfter(new Date(), new Date(answer?.activeEndDate));
        },

        getAnswerTitleText(answer) {
            const dayOfWeek = TimeUtils.getFormattedTime(answer.activeStartDate, 'dddd');
            const startDate = TimeUtils.getFormattedTime(answer.activeStartDate, 'YYYY/MM/DD');

            if(answer.answerId == this.activeAnswer?.answerId){
                return `Today's Word (${dayOfWeek} ${startDate})`;
            } else {
                return `${dayOfWeek}'s Word (${startDate})`;
            }
        },

        getHintText(answer, index) {
            if (!this.activeAnswerHints || this.activeAnswerHints?.length == 0
                || this.activeAnswer?.answerId != answer.answerId) {
                return false;
            }

            const activeAnswerHint = this.activeAnswerHints[index];

            return activeAnswerHint?.hintIsUsed ? activeAnswerHint?.hint?.text : null;

            //const usedHint = this.puzzleProgress?.usedHints?.find(hint => hint.hintId == activeAnswerHint?.hintId);
            // return usedHint?.hint?.text;
        },

        // eslint-disable-next-line no-unused-vars
        isShowHintButtonDisabled(answer, index) {
            if (index == 0) {
                return false;
            } else if (!this.activeAnswerHints || this.activeAnswerHints?.length == 0
                || this.activeAnswer?.answerId != answer.answerId) {
                return true;
            }

            const activeAnswerHint = this.activeAnswerHints[index - 1];
            return !activeAnswerHint?.hintIsUsed;
        },

        onShowHintButtonClick(answer, index) {
            const hint = this.activeAnswerHints[index];
            PuzzleService.usePuzzleHintForCurrentUser(answer.puzzleId, hint.hintId).then(resp => {
                if (resp.data.status == 'success') {
                    this.$emit("refresh-puzzle-game-progress");
                }
            });
        },

        isAnswerFound(answer) {        
            return answer?.answer?.found;
        },

        getAnswerText(answer) {        
            return this.isAnswerFound(answer) ? answer.answer.text : atob(answer.answer.text).toLowerCase();
        },

        showAnswerButton(answer) {
            if (this.puzzle.isActive || this.isAnswerFound(answer)
                || !this.activeAnswerHints || this.activeAnswerHints?.length == 0
                || this.activeAnswer?.answerId != answer.answerId) {
                return false;
            }
            
            const activeAnswerHint = this.activeAnswerHints[this.activeAnswerHints.length - 1];
            return activeAnswerHint?.hintIsUsed;
        },

        onShowAnswerButtonClick(answer) {
            PuzzleService.showPuzzleAnswerForCurrentUser(answer.puzzleId, answer.answerId).then(resp => {
                if (resp.data.status == 'success') {
                    this.$emit("refresh-puzzle-game-progress");
                }
            });
        },

        startGame(puzzle, activeAnswers, puzzleProgress, activeAnswer) {
            this.puzzle = puzzle;
            this.puzzleProgress = puzzleProgress;
            this.activeAnswers = activeAnswers;
            this.answers = puzzle.answers;
            this.activeAnswer = activeAnswer;

            const index = this.answers.findIndex(a => a.answerId == this.activeAnswer?.answerId);
            if(index) {
                this.answers.unshift(this.answers.splice(index, 1)[0]);
            }

            this.activeAnswerHints = this.activeAnswer?.hints;

            this.activeAnswerHints?.forEach(activeAnswerHint => {
                const usedHint = this.puzzleProgress?.usedHints?.find(hint => hint.hintId == activeAnswerHint.hintId);
                activeAnswerHint["hintIsUsed"] = usedHint !== undefined;
            });
        },
    }
};
</script>

<style scoped>
.answer-found-input {
    border-color: #33cc99;
    color: #009161;
}

.answer-title {
    text-align: center;
    margin-bottom: 4px;
}

.card {
    margin-bottom: 16px;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 30px;
}

.answer-items-container {
    height: calc(100vh - 215px);
    overflow-y: scroll;
    overflow-x: hidden;
    white-space: break-spaces;
    scrollbar-color: #bfbfbf #f2f2f2;
    scrollbar-width: thin;
}

.answer-item-container {
    border-bottom: 1px solid #323632;
    padding-bottom: 8px;
    margin-bottom: 4px;
}

.answer-items-container::-webkit-scrollbar {
    width: 10px;
}

.answer-items-container::-webkit-scrollbar-track {
    background-color: #f2f2f2;
    opacity: 1;
    transition: background-color 0.2s;
}

.answer-items-container::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background: #bfbfbf;
    /* color of the scroll thumb */
    border-radius: 10px;
    /* roundness of the scroll thumb */
}
</style>